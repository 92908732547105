import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import client from '../../api/client';
import { NotificationTypes } from './interfaces';
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILURE,
} from './types';
import { AppAction } from './../apptypes';
import { AppState } from './../store/index';

export const getNotifications = (): ThunkAction<void, AppState, unknown, AppAction> => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch({
    type: GET_NOTIFICATIONS_REQUEST,
  });
  try {
    const res = await client.fetchNotifications();
    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATIONS_FAILURE,
      error: error,
    });
  }
};

export const clearNotifications = (
  notificationType: NotificationTypes,
): ThunkAction<void, AppState, unknown, AppAction> => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: DELETE_NOTIFICATIONS_REQUEST,
  });

  try {
    const response = await client.deleteNotifications(notificationType);
    dispatch({
      type: DELETE_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NOTIFICATIONS_FAILURE,
      error: error,
    });
  }
};
