import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoginScreen from '../screens/LoginScreen/LoginScreen';
import RestaurantsListScreen from '../screens/RestaurantsListScreen/RestaurantsListScreen';
import ColleaguesListScreen from '../screens/ColleaguesListScreen/ColleaguesListScreen';
import UsersListScreen from '../screens/UsersListScreen/UsersListScreen';
import AddColleagueScreen from '../screens/AddColleagueScreen/AddColleagueScreen';
import AddRestaurantScreen from '../screens/AddRestaurantScreen/AddRestaurantScreen';
import AddUserScreen from '../screens/AddUserScreen/AddUserScreen';
import EditCollagueScreen from '../screens/EditColleagueScreen/EditColleagueScreen';
import EditRestaurantsScreen from '../screens/EditRestaurantsScreen/EditRestaurantsScreen';
import ReportsScreen from '../screens/ReportsScreen/ReportsScreen';
import NewRegistrationsReportScreen from '../screens/NewRegistrationsReportScreen/NewRegistrationsReportScreen';
import ActivityReportScreen from '../screens/ActivityReportScreen/ActivityReportScreen';
import ComplaintsReportScreen from '../screens/ComplaintsReportScreen/ComplaintsReportScreen';
import FoodParametersReportScreen from '../screens/FoodParametersReportScreen/FoodParametersReportScreen';
import ComplaintListScreen from '../screens/ComplaintListScreen/ComplaintListScreen';
import ComplaintDetailsScreen from '../screens/ComplaintDetailsScreen/ComplaintDetailsScreen';
import ForgottenPasswordScreen from '../screens/ForgottenPasswordScreen/ForgottenPasswordScreen';
import setAxiosInterceptors from '../api/interceptors';
import { getNotifications } from '../redux/notifications/notifications.actions';
import { setAuthorizationToken } from '../api/axiosInstance';
import { AppState } from '../redux/store';
import NavBar from './NavBar';
import PrivateRoute from './PrivateRoute';

const RootRouter: React.FC = () => {
  const { isAuthenticated, accessToken } = useSelector((state: AppState) => state.auth);
  accessToken && setAuthorizationToken(accessToken);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getNotifications());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    setAxiosInterceptors();
  }, []);

  return (
    <div className="mainContainer">
      <Router>
        {isAuthenticated && <NavBar />}
        <div className="mainContentContainer">
          <Switch>
            <Route exact path="/" component={LoginScreen} />
            <Route path="/forgotten_password" component={ForgottenPasswordScreen} />
            <PrivateRoute path="/restaurants_list" component={RestaurantsListScreen} />
            <PrivateRoute path="/colleagues_list" component={ColleaguesListScreen} />
            <PrivateRoute path="/users_list" component={UsersListScreen} />
            <PrivateRoute path="/add_colleague" component={AddColleagueScreen} />
            <PrivateRoute path="/add_restaurant" component={AddRestaurantScreen} />
            <PrivateRoute path="/add_user" component={AddUserScreen} />
            <PrivateRoute path="/edit_colleagues" component={EditCollagueScreen} />
            <PrivateRoute path="/edit_restaurants" component={EditRestaurantsScreen} />
            <PrivateRoute exact path="/reports" component={ReportsScreen} />
            <PrivateRoute
              path="/reports/new_registrations"
              component={NewRegistrationsReportScreen}
            />
            <PrivateRoute path="/reports/activity" component={ActivityReportScreen} />
            <PrivateRoute path="/reports/complaints" component={ComplaintsReportScreen} />
            <PrivateRoute path="/reports/food_parameters" component={FoodParametersReportScreen} />
            <PrivateRoute path="/complaint_list" component={ComplaintListScreen} />
            <PrivateRoute path="/complaint" component={ComplaintDetailsScreen} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default RootRouter;
