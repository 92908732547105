import { AxiosError } from 'axios';
import { Irestaurant, IrestaurantType, Icountry, IrestaurantName } from './interfaces';

export const ADD_RESTAURANT_REQUEST = 'ADD_RESTAURANT_REQUEST';
export const ADD_RESTAURANT_SUCCESS = 'ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_FAILURE = 'ADD_RESTAURANT_FAILURE';
export const GET_RESTAURANTS_REQUEST = 'GET_RESTAURANTS_REQUEST';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_FAILURE = 'GET_RESTAURANTS_FAILURE';
export const GET_RESTAURANTS_NEXT_PAGE_SUCCESS = 'GET_RESTAURANTS_NEXT_PAGE_SUCCESS';
export const GET_RESTAURANTS_LAST_PAGE_REACHED = 'GET_RESTAURANTS_LAST_PAGE_REACHED';
export const RESET_RESTAURANTS_LIST = 'RESET_RESTAURANTS_LIST';
export const GET_RESTAURANT_REQUEST = 'GET_RESTAURANT_REQUEST';
export const GET_RESTAURANT_FAILURE = 'GET_RESTAURANT_FAILURE';
export const GET_RESTAURANT_SUCCESS = 'GET_RESTAURANT_SUCCESS';
export const EDIT_RESTAURANT_REQUEST = 'EDIT_RESTAURANT_REQUEST';
export const EDIT_RESTAURANT_SUCCESS = 'EDIT_RESTAURANT_SUCCESS';
export const EDIT_RESTAURANT_FAILURE = 'EDIT_RESTAURANT_FAILURE';
export const DELETE_RESTAURANT_REQUEST = 'DELETE_RESTAURANT_REQUEST';
export const DELETE_RESTAURANT_SUCCESS = 'DELETE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_FAILURE = 'DELETE_RESTAURANT_FAILURE';
export const GET_RESTAURANT_TYPES_REQUEST = 'GET_RESTAURANT_TYPES_REQUEST';
export const GET_RESTAURANT_TYPES_FAILURE = 'GET_RESTAURANT_TYPES_FAILURE';
export const GET_RESTAURANT_TYPES_SUCCESS = 'GET_RESTAURANT_TYPES_SUCCESS';
export const GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE';
export const GET_RESTAURANT_LIST_REQUEST = 'GET_RESTAURANT_LIST_REQUEST';
export const GET_RESTAURANT_LIST_SUCCESS = 'GET_RESTAURANT_LIST_SUCCESS';
export const GET_RESTAURANT_LIST_FAILURE = 'GET_RESTAURANT_LIST_FAILURE';

export interface IpostRestaurantsRequestAction {
  type: typeof ADD_RESTAURANT_REQUEST;
}

export interface IpostRestaurantsSuccessAction {
  type: typeof ADD_RESTAURANT_SUCCESS;
}

export interface IpostRestaurantsFailureAction {
  type: typeof ADD_RESTAURANT_FAILURE;
  error: AxiosError;
}

export interface IgetRestaurantsRequestAction {
  type: typeof GET_RESTAURANTS_REQUEST;
}
export interface IgetRestaurantsSuccessAction {
  type: typeof GET_RESTAURANTS_SUCCESS;
  payload: Irestaurant[];
}
export interface IgetRestaurantsFailureAction {
  type: typeof GET_RESTAURANTS_FAILURE;
  error: AxiosError;
}

export interface IgetRestaurantsNextPageSuccessAction {
  type: typeof GET_RESTAURANTS_NEXT_PAGE_SUCCESS;
  payload: Irestaurant[];
  page: number;
}

export interface IgetRestaurantsLastPageReachedAction {
  type: typeof GET_RESTAURANTS_LAST_PAGE_REACHED;
}

export interface IresetRestaurantsList {
  type: typeof RESET_RESTAURANTS_LIST;
}

export interface IgetRestaurantRequestAction {
  type: typeof GET_RESTAURANT_REQUEST;
}
export interface IgetRestaurantSuccessAction {
  type: typeof GET_RESTAURANT_SUCCESS;
  payload: Irestaurant;
}
export interface IgetRestaurantFailureAction {
  type: typeof GET_RESTAURANT_FAILURE;
  error: AxiosError;
}

export interface IeditRestaurantsRequestAction {
  type: typeof EDIT_RESTAURANT_REQUEST;
}

export interface IeditRestaurantsSuccessAction {
  type: typeof EDIT_RESTAURANT_SUCCESS;
}

export interface IeditRestaurantsFailureAction {
  type: typeof EDIT_RESTAURANT_FAILURE;
  error: AxiosError;
}

export interface IdeleteRestaurantsRequestAction {
  type: typeof DELETE_RESTAURANT_REQUEST;
}

export interface IdeleteRestaurantsSuccessAction {
  type: typeof DELETE_RESTAURANT_SUCCESS;
}

export interface IdeleteRestaurantsFailureAction {
  type: typeof DELETE_RESTAURANT_FAILURE;
  error: AxiosError;
}

export interface IgetRestaurantTypesRequestAction {
  type: typeof GET_RESTAURANT_TYPES_REQUEST;
}
export interface IgetRestaurantTypesSuccessAction {
  type: typeof GET_RESTAURANT_TYPES_SUCCESS;
  payload: IrestaurantType[];
}
export interface IgetRestaurantTypesFailureAction {
  type: typeof GET_RESTAURANT_TYPES_FAILURE;
  error: AxiosError;
}

export interface IgetCountryListRequestAction {
  type: typeof GET_COUNTRY_LIST_REQUEST;
}
export interface IgetCountryListSuccessAction {
  type: typeof GET_COUNTRY_LIST_SUCCESS;
  payload: Icountry[];
}
export interface IgetCountryListFailureAction {
  type: typeof GET_COUNTRY_LIST_FAILURE;
  error: AxiosError;
}
export interface IgetRestaurantListRequestAction {
  type: typeof GET_RESTAURANT_LIST_REQUEST;
}
export interface IgetRestaurantListSuccessAction {
  type: typeof GET_RESTAURANT_LIST_SUCCESS;
  payload: IrestaurantName[];
}
export interface IgetRestaurantListFailureAction {
  type: typeof GET_RESTAURANT_LIST_FAILURE;
  error: AxiosError;
}

export type RestaurantActionTypes =
  | IpostRestaurantsRequestAction
  | IpostRestaurantsSuccessAction
  | IpostRestaurantsFailureAction
  | IgetRestaurantsRequestAction
  | IgetRestaurantsSuccessAction
  | IgetRestaurantsFailureAction
  | IgetRestaurantsNextPageSuccessAction
  | IgetRestaurantsLastPageReachedAction
  | IresetRestaurantsList
  | IgetRestaurantRequestAction
  | IgetRestaurantSuccessAction
  | IgetRestaurantFailureAction
  | IeditRestaurantsRequestAction
  | IeditRestaurantsSuccessAction
  | IeditRestaurantsFailureAction
  | IdeleteRestaurantsRequestAction
  | IdeleteRestaurantsSuccessAction
  | IdeleteRestaurantsFailureAction
  | IgetRestaurantTypesRequestAction
  | IgetRestaurantTypesSuccessAction
  | IgetRestaurantTypesFailureAction
  | IgetCountryListRequestAction
  | IgetCountryListSuccessAction
  | IgetCountryListFailureAction
  | IgetRestaurantListRequestAction
  | IgetRestaurantListSuccessAction
  | IgetRestaurantListFailureAction;
