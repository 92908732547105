import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportTypeEnum, IreportParameters } from '../../redux/reports/interfaces';
import { fetchReportParameters } from '../../redux/reports/reports.actions';
import { AppState } from '../../redux/store';

/**
 * Hook for load the reports parameters.
 * @param reportType - The report's type.
 */
export const useLoadReportParams = (reportType: ReportTypeEnum): [boolean, IreportParameters] => {
  const { isLoading, parameters } = useSelector((state: AppState) => state.reports);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!parameters[reportType].length) {
      dispatch(fetchReportParameters());
    }
  }, [parameters, dispatch, reportType]);

  return [isLoading, parameters];
};
