import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table';
import Button from '../../components/Button';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';

const ReportsScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tableDataConfig = [
    {
      labelKey: 'reportsScreen.newRegistrations',
      navigationRoute: 'new_registrations',
    },
    {
      labelKey: 'reportsScreen.activity',
      navigationRoute: 'activity',
    },
    {
      labelKey: 'reportsScreen.complaints',
      navigationRoute: 'complaints',
    },
    {
      labelKey: 'reportsScreen.foodParameters',
      navigationRoute: 'food_parameters',
    },
  ];

  const renderTableData = (): ReactElement[] =>
    tableDataConfig.map((data, index) => (
      <tr key={index}>
        <td>{t(data.labelKey)}</td>
        <td>
          <Button
            className="is-small"
            onClick={(): void => history.push(`reports/${data.navigationRoute}`)}
          >
            {t('reportsScreen.open')}
          </Button>
        </td>
      </tr>
    ));

  return (
    <div className="container reportsScreenContainer">
      <Table title={t('reportsScreen.title')} header={[t('reportsScreen.reportTypes')]}>
        {renderTableData()}
      </Table>
    </div>
  );
};

export default withErrorMessage(ReportsScreen);
