import React from 'react';
import { useTranslation } from 'react-i18next';
import DateIntervalPicker, { SelectionRange } from '../../../../components/DateIntervalPicker';

interface ReportIntervalProps {
  dateRange: SelectionRange[];
  setDateRange: (range: SelectionRange[]) => void;
}

/**
 * Renders the date range picker for the report screens.
 * Displayed on the new registrations and activity report screens.
 * @param dateRange - The selected date range.
 * @param setDateRange - A function what will be called when the date range changed.
 */
const ReportInterval: React.FC<ReportIntervalProps> = ({ dateRange, setDateRange }) => {
  const { t } = useTranslation();

  return (
    <div className="reportIntervalContainer">
      <div className="reportIntervalTitle">{t('reportsScreen.common.reportInterval')}:</div>
      <DateIntervalPicker
        selectionRange={dateRange}
        maxDate={new Date()}
        onChange={(range: SelectionRange[]): void => setDateRange(range)}
      />
    </div>
  );
};

export default ReportInterval;
