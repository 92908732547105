import { USERACCESSLEVEL } from '../users/enums';
import {
  AuthActionTypes,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST,
  ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS,
  ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE,
} from './types';
import { IauthState } from './interfaces';

const defaultAuthState: IauthState = {
  accessToken: '',
  refreshToken: '',
  user: null,
  isLoading: false,
  errorMessage: null,
  isAuthenticated: false,
  isSuccess: false,
};

export default (state = defaultAuthState, action: AuthActionTypes): IauthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        accessToken: '',
        refreshToken: '',
        user: null,
        isLoading: true,
        errorMessage: null,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        user: action.payload.data,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isAuthenticated:
          !!action.payload.accessToken && action.payload.data.type === USERACCESSLEVEL.ADMIN,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.error.response?.data.message,
        accessToken: '',
        refreshToken: '',
        isLoading: false,
        user: null,
        isAuthenticated: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...defaultAuthState,
      };

    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        errorMessage: action.error.response?.data.message,
        accessToken: '',
        refreshToken: '',
        isLoading: false,
      };
    case ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errorMessage: null,
      };

    case ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        errorMessage: null,
      };

    case ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: action.error.response?.data.message,
      };

    default:
      return state;
  }
};
