import React, { useEffect, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  getUsers,
  getUsersNextPage,
  resetUsersList,
  toggleActivateUser,
} from '../../redux/users/user.actions';
import { AppState } from '../../redux/store';
import { Loader, SearchBar, Table, Button } from '../../components';
import { usePageBottomReachedEffect } from '../../hooks/usePageBottomReachedEffect';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { clearNotifications } from '../../redux/notifications/notifications.actions';
import { NotificationTypes } from '../../redux/notifications/interfaces';
import { useFetchOnHighRes } from '../../hooks/useFetchOnHighRes';

const UsersListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { users, reachedLastPage } = useSelector((state: AppState) => state.users);
  const isLoading = useSelector((state: AppState) => state.users.isLoading);
  const [searchValue, setSearchValue] = useState('');
  const tableId = 'userListTable';

  const toggleUserState = async (id: string | undefined, nextUserState: boolean): Promise<void> => {
    dispatch(toggleActivateUser(id, nextUserState));
  };

  // Fetch users
  useEffect(() => {
    dispatch(resetUsersList());
    dispatch(getUsers());
    dispatch(clearNotifications(NotificationTypes.users));
  }, [dispatch]);

  usePageBottomReachedEffect((): void => {
    dispatch(getUsersNextPage(searchValue));
  });

  useFetchOnHighRes((): void => {
    if (!reachedLastPage && users) {
      dispatch(getUsersNextPage(searchValue));
    }
  }, tableId);

  const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setSearchValue(event.currentTarget.value);
      dispatch(resetUsersList());
      dispatch(getUsers(event.currentTarget.value));
    }
  };

  const navigateToAddUser = (): unknown => history.push('add_user');

  const renderLoading = (): ReactElement => <Loader isLoading={isLoading} />;

  const renderSearchBar = (): ReactElement => <SearchBar onKeyPress={handleSearchKeyPress} />;

  const renderTable = (): ReactElement => {
    return (
      <>
        <Table
          id={tableId}
          header={[t('name'), t('email'), t('userListScreen.successfullOrders')]}
          title={t('navbar.usersTitle')}
          actionButtonText={t('userListScreen.addUserButton')}
          actionButtonClick={navigateToAddUser}
        >
          {users.map(({ firstName, lastName, _id, email, successfulOrderCount, disabled }) => {
            return (
              <tr key={_id}>
                <td>{`${firstName} ${lastName}`}</td>
                <td>{email}</td>
                <td>{successfulOrderCount || 0}</td>
                <td>
                  <Button
                    onClick={(): Promise<void> => toggleUserState(_id, !disabled)}
                    className="is-small"
                  >
                    {disabled
                      ? t('userListScreen.activateButton')
                      : t('userListScreen.inactivateButton')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>
        {renderLoading()}
      </>
    );
  };

  return (
    <div className="container">
      {renderSearchBar()}
      {renderTable()}
    </div>
  );
};

export default withErrorMessage(UsersListScreen);
