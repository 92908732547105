import { AxiosError } from 'axios';
import { Iuser } from './../users/interfaces';
export const GET_COLLEAGUES_REQUEST = 'GET_COLLEAGUES_REQUEST';
export const GET_COLLEAGUES_SUCCESS = 'GET_COLLEAGUES_SUCCESS';
export const GET_COLLEAGUES_FAILURE = 'GET_COLLEAGUES_FAILURE';
export const GET_COLLEAGUES_NEXT_PAGE_SUCCESS = 'GET_COLLEAGUES_NEXT_PAGE_SUCCESS';
export const GET_COLLEAGUES_LAST_PAGE_REACHED = 'GET_COLLEAGUES_LAST_PAGE_REACHED';
export const RESET_COLLEAGUES_LIST = 'RESET_COLLEAGUES_LIST';
export const ADD_COLLEAGUE_REQUEST = 'ADD_COLLEAGUE_REQUEST';
export const ADD_COLLEAGUE_SUCCESS = 'ADD_COLLEAGUE_SUCCESS';
export const ADD_COLLEAGUE_FAILURE = 'ADD_COLLEAGUE_FAILURE';
export const EDIT_COLLEAGUE_REQUEST = 'EDIT_COLLEAGUE_REQUEST';
export const EDIT_COLLEAGUE_SUCCESS = 'EDIT_COLLEAGUE_SUCCESS';
export const EDIT_COLLEAGUE_FAILURE = 'EDIT_COLLEAGUE_FAILURE';
export const GET_COLLEAGUE_REQUEST = 'GET_COLLEAGUE_REQUEST';
export const GET_COLLEAGUE_SUCCESS = 'GET_COLLEAGUE_SUCCESS';
export const GET_COLLEAGUE_FAILURE = 'GET_COLLEAGUE_FAILURE';
export const DELETE_COLLEAGUE_REQUEST = 'DELETE_COLLEAGUE_REQUEST';
export const DELETE_COLLEAGUE_SUCCESS = 'DELETE_COLLEAGUE_SUCCESS';
export const DELETE_COLLEAGUE_FAILURE = 'DELETE_COLLEAGUE_FAILURE';

export interface IgetColleagueRequestAction {
  type: typeof GET_COLLEAGUE_REQUEST;
}
export interface IgetColleagueSuccessAction {
  type: typeof GET_COLLEAGUE_SUCCESS;
  payload: Iuser;
}
export interface IgetColleagueFailureAction {
  type: typeof GET_COLLEAGUE_FAILURE;
  error: AxiosError;
}
export interface IgetColleaguesRequestAction {
  type: typeof GET_COLLEAGUES_REQUEST;
}
export interface IgetColleaguesSuccessAction {
  type: typeof GET_COLLEAGUES_SUCCESS;
  payload: Iuser[];
}

export interface IgetColleaguesFailureAction {
  type: typeof GET_COLLEAGUES_FAILURE;
  error: AxiosError;
}

export interface IgetColleaguesNextPageSuccessAction {
  type: typeof GET_COLLEAGUES_NEXT_PAGE_SUCCESS;
  payload: Iuser[];
  page: number;
}

export interface IgetColleaguesLastPageReachedAction {
  type: typeof GET_COLLEAGUES_LAST_PAGE_REACHED;
}

export interface IresetColleaguesList {
  type: typeof RESET_COLLEAGUES_LIST;
}

export interface IaddColleagueRequestAction {
  type: typeof ADD_COLLEAGUE_REQUEST;
}

export interface IaddColleagueSuccessAction {
  type: typeof ADD_COLLEAGUE_SUCCESS;
}

export interface IaddColleagueFailureAction {
  type: typeof ADD_COLLEAGUE_FAILURE;
  error: AxiosError;
}

export interface IeditColleagueRequestAction {
  type: typeof EDIT_COLLEAGUE_REQUEST;
}

export interface IeditColleagueSuccessAction {
  type: typeof EDIT_COLLEAGUE_SUCCESS;
}

export interface IeditColleagueFailureAction {
  type: typeof EDIT_COLLEAGUE_FAILURE;
  error: AxiosError;
}

export interface IdeleteColleagueRequestAction {
  type: typeof DELETE_COLLEAGUE_REQUEST;
}

export interface IdeleteColleagueSuccessAction {
  type: typeof DELETE_COLLEAGUE_SUCCESS;
}

export interface IdeleteColleagueFailureAction {
  type: typeof DELETE_COLLEAGUE_FAILURE;
  error: AxiosError;
}

export type ColleaguesActionTypes =
  | IgetColleaguesRequestAction
  | IgetColleaguesSuccessAction
  | IgetColleaguesFailureAction
  | IgetColleaguesNextPageSuccessAction
  | IgetColleaguesLastPageReachedAction
  | IresetColleaguesList
  | IaddColleagueRequestAction
  | IaddColleagueSuccessAction
  | IaddColleagueFailureAction
  | IeditColleagueRequestAction
  | IeditColleagueSuccessAction
  | IeditColleagueFailureAction
  | IgetColleagueFailureAction
  | IgetColleagueRequestAction
  | IgetColleagueSuccessAction
  | IdeleteColleagueRequestAction
  | IdeleteColleagueSuccessAction
  | IdeleteColleagueFailureAction;
