import React, { useState, FormEvent, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, NavLink } from 'react-router-dom';
import { TextInput, Loader, Button } from '../../components';
import logo from '../../resources/images/logo.png';
import * as actions from '../../redux/authentication/authentication.actions';
import { IuserAuth } from '../../redux/authentication/interfaces';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import { validateLoginForm } from '../../utilities/functions/validateFunctions';

const LoginScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);
  const isLoading = useSelector((state: AppState) => state.auth.isLoading);
  const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);

  const [form, setForm] = useState<IuserAuth>({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<IuserAuth>({
    email: '',
    password: '',
  });

  useEffect(() => {
    isAuthenticated && history.push('restaurants_list');
  }, [isAuthenticated, history]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (validateLoginForm(form, setErrors, t)) {
      dispatch(await actions.loginUser(form));
      if (!errorMessage) history.push('/restaurants_list');
    }
  };

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderForm = (): ReactElement => {
    return (
      <div className={`box is-narrow-widescreen ${!errorMessage ? '-login' : '-login-Error'}`}>
        <div className="loginLogo">
          <img src={logo} alt="logo" />
        </div>
        <div className="loginLabel">{t('loginScreen.administratorLogin')}</div>
        <form noValidate onSubmit={onSubmit}>
          <TextInput
            onChange={updateField}
            value={form.email}
            className={`is-medium ${errors.email && 'red-border'}`}
            placeholder={t('loginScreen.username')}
            type="email"
            required
            name="email"
            error={errors.email}
          />
          <TextInput
            onChange={updateField}
            value={form.password}
            className={`is-medium ${errors.email && 'red-border'}`}
            placeholder={t('password')}
            type="password"
            required
            name="password"
            error={errors.password}
            autocomplete="off"
          />
          <NavLink className="navLink" to="/forgotten_password">
            <p className="secondary-button">{t('loginScreen.forgottenPassword')}</p>
          </NavLink>
          <div className="field loginButton">
            <div className="control">
              <Button type="submit" className="is-medium is-fullwidth">
                {t('loginScreen.login')}
              </Button>
              {renderLoading()}
            </div>
          </div>
        </form>
      </div>
    );
  };

  return <div className={!errorMessage ? 'container' : ''}>{renderForm()}</div>;
};

export default withErrorMessage(LoginScreen);
