import React, { useEffect, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { AppState } from '../../redux/store';
import { Loader, Table, Button, SearchBar } from '../../components';
import { usePageBottomReachedEffect } from '../../hooks/usePageBottomReachedEffect';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { statusToStringForComplaint } from '../../utilities/functions/helperFunctions';
import {
  getComplaintList,
  resetComplaintList,
  getComplaintsNextPage,
} from '../../redux/complaints/complaints.actions';
import { clearNotifications } from '../../redux/notifications/notifications.actions';
import { NotificationTypes } from '../../redux/notifications/interfaces';
import { useFetchOnHighRes } from '../../hooks/useFetchOnHighRes';

const ComplaintListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { complaintList, isLoading, reachedLastPage } = useSelector(
    (state: AppState) => state.complaints,
  );
  const [searchValue, setSearchValue] = useState('');
  const tableId = 'complaintListTable';
  useEffect(() => {
    dispatch(resetComplaintList());
    dispatch(getComplaintList());
    dispatch(clearNotifications(NotificationTypes.complaints));
  }, [dispatch]);

  usePageBottomReachedEffect(() => {
    dispatch(getComplaintsNextPage(searchValue));
  });

  useFetchOnHighRes((): void => {
    if (!reachedLastPage && complaintList) {
      dispatch(getComplaintsNextPage(searchValue));
    }
  }, tableId);

  const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setSearchValue(event.currentTarget.value);
      dispatch(resetComplaintList());
      dispatch(getComplaintList(event.currentTarget.value));
    }
  };

  const navigateToComplaintDetails = (_id: string): void =>
    history.push({ pathname: '/complaint', search: `id=${_id}` });

  const renderTable = (): ReactElement => {
    const headerColumns = [
      t('name'),
      t('email'),
      t('complaintListScreen.complaintCreatedDate'),
      t('status'),
    ];
    return (
      <Table id={tableId} header={headerColumns} title={t('complaintListScreen.title')}>
        {complaintList.map(({ _id, userName, userEmail, createdDate, status }) => {
          return (
            <tr key={_id}>
              <td>{userName}</td>
              <td>{userEmail}</td>
              <td>{moment(createdDate).format('YYYY. MM. DD')}</td>
              <td>{statusToStringForComplaint(status)}</td>
              <td>
                <Button onClick={(): void => navigateToComplaintDetails(_id)} className="is-small">
                  {t('complaintListScreen.details')}
                </Button>
              </td>
            </tr>
          );
        })}
      </Table>
    );
  };

  return (
    <div className="container">
      <Loader isLoading={isLoading} />
      <SearchBar onKeyPress={handleSearchKeyPress} />
      {renderTable()}
    </div>
  );
};

export default withErrorMessage(ComplaintListScreen);
