import { ThunkAction } from 'redux-thunk';
import client from '../../api/client';
import { AppState } from './../store/index';
import { Iuser, UserEnum } from './interfaces';
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  GET_USERS_NEXT_PAGE_SUCCESS,
  GET_USERS_LAST_PAGE_REACHED,
  RESET_USERS_LIST,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  TOGGLE_ACTIVATE_USER_REQUEST,
  TOGGLE_ACTIVATE_USER_FAILURE,
  TOGGLE_ACTIVATE_USER_SUCCESS,
} from './types';
import { AppAction } from './../apptypes';

export const getUsers = (
  searchValue?: string,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_USERS_REQUEST,
  });

  try {
    const res = await client.fetchUsers(0, searchValue);
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_FAILURE,
      error: error,
    });
  }
};

export const addUser = (user: Iuser): ThunkAction<void, AppState, {}, AppAction> => async (
  dispatch,
): Promise<void> => {
  const newUser = { ...user, type: UserEnum.USER };
  dispatch({
    type: ADD_USER_REQUEST,
  });
  try {
    await client.addUser(newUser);
    dispatch({
      type: ADD_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_USER_FAILURE,
      error: error,
    });
  }
};

export const getUsersNextPage = (
  searchValue?: string,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch, getState): Promise<void> => {
  const isLoading = getState().users.isLoading;
  if (isLoading) {
    return;
  }

  const reachedLastPage = getState().users.reachedLastPage;
  if (!reachedLastPage) {
    dispatch({
      type: GET_USERS_REQUEST,
    });

    try {
      const nextPage = getState().users.page + 1;
      const res = await client.fetchUsers(nextPage, searchValue);

      if (res.data.length === 0) {
        dispatch({
          type: GET_USERS_LAST_PAGE_REACHED,
        });
      } else {
        dispatch({
          type: GET_USERS_NEXT_PAGE_SUCCESS,
          payload: res.data,
          page: nextPage,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_USERS_FAILURE,
        error: error,
      });
    }
  }
};

export const toggleActivateUser = (
  id: string | undefined,
  nextUserState: boolean,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch, getState): Promise<void> => {
  dispatch({
    type: TOGGLE_ACTIVATE_USER_REQUEST,
  });

  try {
    await client.activateUserToggle(nextUserState, id);
    dispatch({
      type: TOGGLE_ACTIVATE_USER_SUCCESS,
    });
    getUsers()(dispatch, getState, {});
  } catch (error) {
    dispatch({
      type: TOGGLE_ACTIVATE_USER_FAILURE,
      error,
    });
  }
};

export const resetUsersList = (): AppAction => ({ type: RESET_USERS_LIST });

export const getUser = (id: string): ThunkAction<void, AppState, {}, AppAction> => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: GET_USER_REQUEST,
  });

  try {
    const res = await client.fetchUser(id);
    dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAILURE,
      error: error,
    });
  }
};
