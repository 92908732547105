import React from 'react';
import { TFunctionResult } from 'i18next';

interface ButtonProps extends React.ButtonHTMLAttributes<unknown> {
  children: React.ReactChild | TFunctionResult;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => Promise<void> | void | unknown;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className, type, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={`button customButton ${className}`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
