import React from 'react';
import { TextInput } from '../../components';
import { SliderProps } from '../../resources/interfaces';

const Slider: React.FC<SliderProps> = ({ value, onChange }) => {
  return (
    <div className="field">
      <div className="slider-container control">
        <input
          id="sliderWithValue"
          className="slider is-fullwidth"
          step="1"
          min="0"
          max="100"
          value={value}
          type="range"
          onChange={onChange}
        />
        <TextInput
          className="is-medium"
          type="number"
          step="1"
          min="0"
          max="100"
          value={value}
          onChange={onChange}
          hasIcon
        />
      </div>
    </div>
  );
};

export default Slider;
