import {
  IloginResponse,
  IuserAuth,
  IrefreshTokenResponse,
} from '../redux/authentication/interfaces';
import { IuserResponse, Iuser, IsingleUserResponse } from '../redux/users/interfaces';
import {
  IrestaurantResponse,
  Irestaurant,
  IsingleRestaurantResponse,
  IrestaurantTypeResponse,
  IcountryListResponse,
  IrestaurantListResponse,
} from '../redux/restaurants/interfaces';
import {
  IcolleagueResponse,
  Icolleague,
  IgetColleagueResponse,
} from '../redux/colleagues/interfaces';
import { IforgottenPasswordEmailRequestAction } from '../redux/authentication/types';
import {
  IcomplaintsResponse,
  IcomplaintResponse,
  IcomplaintTypesResponse,
} from '../redux/complaints/interfaces';
import { USERTYPE } from './../resources/enums';
import {
  IactivityAllResponse,
  IactivityRestaurantsReportResponse,
  IactivityUsersResponse,
  InewRegistrationsReportResponse,
  IrestaurantXlsResponse,
  IcomplaintXlsResponse,
  IfoodParametersReportResponse,
  IcomplaintsReportResponse,
} from './../redux/reports/interfaces';
import {
  InotificationResponse,
  NotificationTypes,
  InotificationDeleteResponse,
} from './../redux/notifications/interfaces';
import {
  LOGIN_URL,
  GET_RESTAURANTS_URL,
  GET_RESTAURANT_URL,
  GET_USERS_URL,
  ADD_RESTAURANT_URL,
  ADD_USER_URL,
  COLLEAGUE_USER_TYPE,
  NORMAL_USER_TYPE,
  ADD_COLLEAGUE_URL,
  GET_USER_URL,
  EDIT_RESTAURANT_URL,
  EDIT_USER_URL,
  REFRESH_TOKEN_URL,
  DELETE_RESTAURANT_URL,
  DELETE_USER_URL,
  NOTIFICATIONS_URL,
  FORGOTTEN_PASSWORD_URL,
  GET_RESTAURANT_TYPES_URL,
  COUNTRY_LIST_URL,
  GET_RESTAURANT_ACITVITY_URL,
  GET_COMPLAINTS_URL,
  GET_COMPLAINT_URL,
  CLOSE_COMPLAINT_URL,
  GET_COMPLAINT_TYPES_URL,
  GET_RESTAURANT_XLSX_URL,
  GET_COMPLAINT_XLSX_URL,
  GET_NEW_REGISTRATIONS_URL,
  GET_COMPLAINTS_REPORTS_URL,
  SUMMARY_REPORT_URL,
  GET_USER_ACTIVITY_URL,
  RESTAURANT_LIST_URL,
  GET_FOOD_PARAMETERS_REPORT_URL,
} from './urls';
import axiosInstance, { setAuthorizationToken } from './axiosInstance';

class Client {
  // AUTH RELATED CALLS

  login = async (userCreds: IuserAuth): Promise<IloginResponse> => {
    const response = await axiosInstance.post(LOGIN_URL, userCreds);
    setAuthorizationToken(response.data.accessToken);
    return response.data;
  };

  refreshAccessToken = async (refreshToken: string): Promise<IrefreshTokenResponse> => {
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const params = { refreshToken: refreshToken, clientSecret: clientSecret };

    const response = await axiosInstance.post(REFRESH_TOKEN_URL, params);
    return response.data;
  };

  // COLLEAGUES CALLS (ADMIN USERS ONLY)

  fetchColleagues = async (page = 0, searchValue?: string): Promise<IcolleagueResponse> => {
    const query = `&query=${searchValue}`;
    const response = await axiosInstance.get(
      `${GET_USERS_URL}/${page}?type=${COLLEAGUE_USER_TYPE}${searchValue ? query : ''}`,
    );
    return response.data;
  };

  addColleague = async (colleague: Icolleague): Promise<IcolleagueResponse> => {
    const response = await axiosInstance.post(ADD_COLLEAGUE_URL, colleague);
    return response.data;
  };

  sendForgotPassWordEmail = async (email: string): Promise<Iuser> => {
    const response = await axiosInstance.post(FORGOTTEN_PASSWORD_URL, email);
    return response.data;
  };

  fetchColleague = async (id: string | undefined): Promise<IgetColleagueResponse> => {
    const response = await axiosInstance.get(`${GET_USER_URL}/${id}`);
    return response.data;
  };

  // RESTAURANT CALLS

  fetchRestaurants = async (page = 0, searchValue?: string): Promise<IrestaurantResponse> => {
    const query = `?query=${searchValue}`;
    const response = await axiosInstance.get(
      `${GET_RESTAURANTS_URL}/${page}${searchValue ? query : ''}`,
    );
    return response.data;
  };

  addRestaurant = async (restaurant: Irestaurant): Promise<IrestaurantResponse> => {
    const response = await axiosInstance.post(ADD_RESTAURANT_URL, restaurant);
    return response.data;
  };

  fetchRestaurant = async (id: string): Promise<IsingleRestaurantResponse> => {
    const response = await axiosInstance.get(`${GET_RESTAURANT_URL}/${id}`);
    return response.data;
  };

  editRestaurant = async (restaurant: Irestaurant, id: string): Promise<IrestaurantResponse> => {
    const response = await axiosInstance.patch(`${EDIT_RESTAURANT_URL}/${id}`, restaurant);
    return response.data;
  };

  deleteRestaurant = async (id: string): Promise<IrestaurantResponse> => {
    const response = await axiosInstance.delete(`${DELETE_RESTAURANT_URL}/${id}`);
    return response.data;
  };

  fetchRestaurantTypes = async (): Promise<IrestaurantTypeResponse> => {
    const response = await axiosInstance.get(GET_RESTAURANT_TYPES_URL);
    return response.data;
  };

  fetchCountryList = async (): Promise<IcountryListResponse> => {
    const response = await axiosInstance.get(COUNTRY_LIST_URL);
    return response.data;
  };

  fetchRestaurantList = async (): Promise<IrestaurantListResponse> => {
    const response = await axiosInstance.get(RESTAURANT_LIST_URL);
    return response.data;
  };
  // USER CALLS

  fetchUsers = async (page = 0, searchValue?: string): Promise<IuserResponse> => {
    const query = `&query=${searchValue}`;
    const response = await axiosInstance.get(
      `${GET_USERS_URL}/${page}?type=${NORMAL_USER_TYPE}${searchValue ? query : ''}`,
    );
    return response.data;
  };

  addUser = async (user: Iuser): Promise<IuserResponse> => {
    const response = await axiosInstance.post(ADD_USER_URL, user);
    return response.data;
  };

  addForgottenPasswordEmail = async (
    email: string,
  ): Promise<IforgottenPasswordEmailRequestAction> => {
    const response = await axiosInstance.post(FORGOTTEN_PASSWORD_URL, { email: email });
    return response.data;
  };

  fetchUser = async (id: string): Promise<IsingleUserResponse> => {
    const response = await axiosInstance.get(`${GET_USER_URL}/${id}`);
    return response.data;
  };

  activateUserToggle = async (nextUserState: boolean, id?: string): Promise<unknown> => {
    const disabled = { disabled: nextUserState };
    const response = await axiosInstance.patch(`${EDIT_USER_URL}/${id}`, disabled);
    return response.data;
  };

  editUser = async (id: string | undefined, user: Iuser): Promise<unknown> => {
    const response = await axiosInstance.patch(`${EDIT_USER_URL}/${id}`, user);
    return response.data;
  };

  deleteUser = async (id: string | undefined): Promise<unknown> => {
    const response = await axiosInstance.delete(`${DELETE_USER_URL}/${id}`);
    return response.data;
  };

  // NOTIFICATIONS

  fetchNotifications = async (): Promise<InotificationResponse> => {
    const response = await axiosInstance.get(`${NOTIFICATIONS_URL}`);
    return response.data;
  };

  deleteNotifications = async (
    notificationType: NotificationTypes,
  ): Promise<InotificationDeleteResponse> => {
    const response = await axiosInstance.delete(NOTIFICATIONS_URL, {
      data: { notificationType: notificationType },
    });
    return response.data;
  };

  // REPORTS
  getComplaintsReport = async (
    from: number,
    to: number,
    type: string[],
    restaurantId: string,
  ): Promise<IcomplaintsReportResponse> => {
    const url = `${GET_COMPLAINTS_REPORTS_URL}?from=${from}&to=${to}&${type
      .map((n) => `type=${n}`)
      .join('&')}&restaurantId=${restaurantId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  };

  getNewRegistrationsReport = async (
    from: number,
    to: number,
    type: string | USERTYPE = USERTYPE.USER,
  ): Promise<InewRegistrationsReportResponse> => {
    const response = await axiosInstance.get(
      `${GET_NEW_REGISTRATIONS_URL}?from=${from}&to=${to}&type=${type}`,
    );
    return response.data;
  };

  getAllActivityReports = async (from: number, to: number): Promise<IactivityAllResponse> => {
    const response = await axiosInstance.get(`${SUMMARY_REPORT_URL}?from=${from}&to=${to}`);
    return response.data;
  };

  fetchRestaurantActivityReports = async (
    from: number,
    to: number,
    page = 0,
  ): Promise<IactivityRestaurantsReportResponse> => {
    const response = await axiosInstance.get(
      `${GET_RESTAURANT_ACITVITY_URL}/${page}?from=${from}&to=${to}`,
    );
    return response.data;
  };

  fetchUsersActivityReports = async (
    from: number,
    to: number,
    page = 0,
  ): Promise<IactivityUsersResponse> => {
    const response = await axiosInstance.get(
      `${GET_USER_ACTIVITY_URL}/${page}?from=${from}&to=${to}`,
    );
    return response.data;
  };

  getFoodParametersReport = async (): Promise<IfoodParametersReportResponse> => {
    const response = await axiosInstance.get(`${GET_FOOD_PARAMETERS_REPORT_URL}`);
    return response.data;
  };

  // COMPLAINTS

  fetchComplaints = async (page = 0, searchValue?: string): Promise<IcomplaintsResponse> => {
    const query = `&query=${searchValue}`;
    const response = await axiosInstance.get(
      `${GET_COMPLAINTS_URL}/${page}?${searchValue ? query : ''}`,
    );
    return response.data;
  };

  fetchRestaurantActivityXlsxData = async (
    from: number,
    to: number,
  ): Promise<IrestaurantXlsResponse> => {
    const response = await axiosInstance.get(`${GET_RESTAURANT_XLSX_URL}?from=${from}&to=${to}`);
    return response.data;
  };

  fetchComplaintXlsxData = async (from: number, to: number): Promise<IcomplaintXlsResponse> => {
    const response = await axiosInstance.get(`${GET_COMPLAINT_XLSX_URL}?from=${from}&to=${to}`);
    return response.data;
  };

  fetchComplaint = async (id: string | undefined): Promise<IcomplaintResponse> => {
    const response = await axiosInstance.get(`${GET_COMPLAINT_URL}?complaintId=${id}`);
    return response.data;
  };

  closeComplaint = async (id: string | undefined): Promise<IcomplaintResponse> => {
    const response = await axiosInstance.delete(`${CLOSE_COMPLAINT_URL}?complaintId=${id}`);
    return response.data;
  };

  fetchComplaintTypes = async (): Promise<IcomplaintTypesResponse> => {
    const response = await axiosInstance.get(GET_COMPLAINT_TYPES_URL);
    return response.data;
  };
}

export default new Client();
