import { GET_NOTIFICATIONS_FAILURE, GET_NOTIFICATIONS_SUCCESS } from './../notifications/types';
import { RESET_ERROR_MESSAGE } from './types';
import {
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS,
  ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE,
} from './../authentication/types';
import {
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
} from './../users/types';
import {
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANTS_SUCCESS,
  ADD_RESTAURANT_FAILURE,
  ADD_RESTAURANT_SUCCESS,
  GET_RESTAURANT_FAILURE,
  GET_RESTAURANT_SUCCESS,
} from './../restaurants/types';
import {
  GET_COLLEAGUES_FAILURE,
  GET_COLLEAGUES_SUCCESS,
  GET_COLLEAGUE_FAILURE,
  GET_COLLEAGUE_SUCCESS,
  EDIT_COLLEAGUE_FAILURE,
  EDIT_COLLEAGUE_SUCCESS,
} from './../colleagues/types';
import {
  GET_REPORT_PARAMETERS_SUCCESS,
  GET_REPORT_PARAMETERS_FAILURE,
  GET_NEW_REGISTRATIONS_REPORT_SUCCESS,
  GET_NEW_REGISTRATIONS_REPORT_FAILURE,
  GET_ACTIVITY_REPORT_SUCCESS,
  GET_ACTIVITY_REPORT_FAILURE,
  GET_COMPLAINTS_REPORT_SUCCESS,
  GET_COMPLAINTS_REPORT_FAILURE,
  GET_FOOD_PARAMETERS_REPORT_SUCCESS,
  GET_FOOD_PARAMETERS_REPORT_FAILURE,
} from './../reports/types';
import {
  GET_COMPLAINT_LIST_SUCCESS,
  GET_COMPLAINT_LIST_FAILURE,
  GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS,
  GET_COMPLAINT_BY_ID_SUCCESS,
  GET_COMPLAINT_BY_ID_FAILURE,
  CLOSE_COMPLAINT_SUCCESS,
  CLOSE_COMPLAINT_FAILURE,
  GET_COMPLAINT_TYPES_SUCCESS,
  GET_COMPLAINT_TYPES_FAILURE,
} from './../complaints/types';
import { AppAction } from './../apptypes';
import { IerrorState } from './interafaces';

const errorDefaultState: IerrorState = {
  errorMessage: null,
};

export default (state = errorDefaultState, action: AppAction): IerrorState => {
  switch (action.type) {
    case GET_COLLEAGUES_FAILURE:
    case GET_RESTAURANTS_FAILURE:
    case GET_RESTAURANT_FAILURE:
    case GET_USERS_FAILURE:
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case ADD_RESTAURANT_FAILURE:
    case ADD_USER_FAILURE:
    case GET_COLLEAGUE_FAILURE:
    case EDIT_COLLEAGUE_FAILURE:
    case GET_NOTIFICATIONS_FAILURE:
    case GET_REPORT_PARAMETERS_FAILURE:
    case GET_NEW_REGISTRATIONS_REPORT_FAILURE:
    case GET_ACTIVITY_REPORT_FAILURE:
    case GET_COMPLAINTS_REPORT_FAILURE:
    case GET_FOOD_PARAMETERS_REPORT_FAILURE:
    case GET_COMPLAINT_LIST_FAILURE:
    case ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE:
    case GET_COMPLAINT_BY_ID_FAILURE:
    case CLOSE_COMPLAINT_FAILURE:
    case GET_COMPLAINT_TYPES_FAILURE:
      return {
        ...state,
        errorMessage: action.error.response?.data.message,
      };
    case RESET_ERROR_MESSAGE:
    case GET_COLLEAGUES_SUCCESS:
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
    case GET_RESTAURANTS_SUCCESS:
    case GET_RESTAURANT_SUCCESS:
    case GET_USERS_SUCCESS:
    case ADD_RESTAURANT_SUCCESS:
    case ADD_USER_SUCCESS:
    case GET_COLLEAGUE_SUCCESS:
    case EDIT_COLLEAGUE_SUCCESS:
    case GET_NOTIFICATIONS_SUCCESS:
    case GET_REPORT_PARAMETERS_SUCCESS:
    case GET_NEW_REGISTRATIONS_REPORT_SUCCESS:
    case GET_ACTIVITY_REPORT_SUCCESS:
    case GET_COMPLAINTS_REPORT_SUCCESS:
    case GET_FOOD_PARAMETERS_REPORT_SUCCESS:
    case GET_COMPLAINT_LIST_SUCCESS:
    case GET_COMPLAINT_BY_ID_SUCCESS:
    case GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS:
    case CLOSE_COMPLAINT_SUCCESS:
    case ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS:
    case GET_COMPLAINT_TYPES_SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};
