import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';

interface ActionButtonsProps {
  onXlsClick?: () => void;
  loadingXls?: boolean;
}

/**
 * Renders the action buttons component for the report screens.
 * Displayed on the new registrations and activity report screens.
 */
const ActionButtons: React.FC<ActionButtonsProps> = ({ onXlsClick, loadingXls }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="buttons actionButtonsContainer">
      <Button className="is-dark" onClick={(): void => history.push('/reports')}>
        {t('reportsScreen.common.backToList')}
      </Button>
      <Button onClick={(): void => history.push(`/reports`)}>
        {t('reportsScreen.common.exportToPdf')}
      </Button>
      <Button className={`${loadingXls ? 'is-loading' : ''}`} onClick={onXlsClick}>
        {t('reportsScreen.common.exportToXlsx')}
      </Button>
    </div>
  );
};

export default ActionButtons;
