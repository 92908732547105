import { SelectionRange } from './../../components/DateIntervalPicker/index';
/**
 * Converts the start date, and the end date to unix timestamp.
 * @param dateRange - daterange object
 */
const useDateConversion = (dateRange: SelectionRange[]): [number, number] => {
  const { startDate, endDate } = dateRange[0];
  const from = startDate.getTime() / 1000;
  const to = endDate.getTime() / 1000;
  return [parseInt(from.toFixed(0)), parseInt(to.toFixed(0))];
};
export default useDateConversion;
