import {
  Irestaurant,
  Iaddress,
  IrestaurantFormObject,
  IrestaurantErrorObject,
} from '../redux/restaurants/interfaces';
import { Iuser } from '../redux/users/interfaces';
import { IinputWithDropdownItem } from '../components/InputWithDropdown';
import { STATUS } from './enums';

export const placeholder = 'placeholder';

export const initialErrorState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordAgain: '',
};
export const initialFormState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordAgain: '',
};

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const defaultBusinessHours = weekDays.map((day) => ({
  from: '',
  to: '',
  checked: false,
  day,
}));

export const defaultAddress: Iaddress = {
  country: '',
  city: '',
  postalCode: '',
  streetName: '',
  streetNumber: '',
};

export const defaultRestaurant: Irestaurant = {
  _id: '',
  name: '',
  businessHours: [],
  restaurantType: [],
  address: defaultAddress,
  email: '',
  numberOfOffers: 0,
  thumbNailImageUrl: '',
  imageUrl: '',
  password: '',
  dividendPercent: 0.12,
  companyName: '',
  companyAddress: '',
  mailingAddress: '',
  taxNumber: '',
  companyRegisterNumber: '',
  bankAccountNumber: '',
  contactPhone: '',
  contactEmail: '',
};

export const defaultUser: Iuser = {
  email: '',
  firstName: '',
  lastName: '',
};

export const statusDropDownItems: IinputWithDropdownItem[] = [
  { label: STATUS.ACTIVE, value: STATUS.ACTIVE },
  { label: STATUS.INACTIVE, value: STATUS.INACTIVE },
];

const initAddress: Iaddress = {
  country: '',
  city: '',
  postalCode: '',
  streetName: '',
  streetNumber: '',
};

export const restaurantInitForm: IrestaurantFormObject = {
  name: '',
  address: initAddress,
  email: '',
  password: '',
  passwordAgain: '',
  restaurantType: [],
  businessHours: defaultBusinessHours,
  firstName: '',
  lastName: '',
  status: '',
  dividendPercent: defaultRestaurant.dividendPercent,
  companyName: '',
  companyAddress: '',
  mailingAddress: '',
  taxNumber: '',
  companyRegisterNumber: '',
  bankAccountNumber: '',
  contactPhone: '',
  contactEmail: '',
};

export const restaurantInitError: IrestaurantErrorObject = {
  name: '',
  country: '',
  city: '',
  postalCode: '',
  streetName: '',
  streetNumber: '',
  email: '',
  password: '',
  passwordAgain: '',
  restaurantType: '',
  firstName: '',
  lastName: '',
  businessHours: '',
  status: '',
  companyName: '',
  companyAddress: '',
  mailingAddress: '',
  taxNumber: '',
  companyRegisterNumber: '',
  bankAccountNumber: '',
  contactPhone: '',
  contactEmail: '',
};

export const VAT = 0.27;
export const WITH_VAT = 1.27;
