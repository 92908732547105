import client from '../../api/client';
import { moveHungaryToFirstPlaceInCountryList } from '../../utilities/functions/helperFunctions';
import { Irestaurant } from './interfaces';
import { AppState, AppThunk } from './../store/index';

import { AppAction } from './../apptypes';
import {
  ADD_RESTAURANT_REQUEST,
  ADD_RESTAURANT_SUCCESS,
  ADD_RESTAURANT_FAILURE,
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANTS_NEXT_PAGE_SUCCESS,
  GET_RESTAURANTS_LAST_PAGE_REACHED,
  RESET_RESTAURANTS_LIST,
  GET_RESTAURANT_REQUEST,
  GET_RESTAURANT_FAILURE,
  GET_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT_REQUEST,
  EDIT_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  GET_RESTAURANT_TYPES_REQUEST,
  GET_RESTAURANT_TYPES_SUCCESS,
  GET_RESTAURANT_TYPES_FAILURE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_RESTAURANT_LIST_FAILURE,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_SUCCESS,
} from './types';

export const addRestaurant = (restaurant: Irestaurant): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: ADD_RESTAURANT_REQUEST,
  });
  try {
    await client.addRestaurant(restaurant);
    dispatch({
      type: ADD_RESTAURANT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_RESTAURANT_FAILURE,
      error: error,
    });
  }
};

export const getRestaurants = (searchValue?: string): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: GET_RESTAURANTS_REQUEST,
  });
  try {
    const response = await client.fetchRestaurants(0, searchValue);
    dispatch({
      type: GET_RESTAURANTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_RESTAURANTS_FAILURE,
      error: error,
    });
  }
};

export const getRestaurantsNextPage = (searchValue?: string): AppThunk => async (
  dispatch,
  getState: () => AppState,
): Promise<void> => {
  const isLoading = getState().restaurants.isLoading;
  if (isLoading) {
    return;
  }

  const reachedLastPage = getState().restaurants.reachedLastPage;
  if (!reachedLastPage) {
    dispatch({
      type: GET_RESTAURANTS_REQUEST,
    });

    try {
      const nextPage = getState().restaurants.page + 1;
      const res = await client.fetchRestaurants(nextPage, searchValue);

      if (res.data.length === 0) {
        dispatch({
          type: GET_RESTAURANTS_LAST_PAGE_REACHED,
        });
      } else {
        dispatch({
          type: GET_RESTAURANTS_NEXT_PAGE_SUCCESS,
          payload: res.data,
          page: nextPage,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_RESTAURANTS_FAILURE,
        error: error,
      });
    }
  }
};

export const resetRestaurantsList = (): AppAction => ({ type: RESET_RESTAURANTS_LIST });

export const getRestaurant = (id: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_RESTAURANT_REQUEST,
  });
  try {
    const response = await client.fetchRestaurant(id);
    dispatch({
      type: GET_RESTAURANT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_RESTAURANT_FAILURE,
      error: error,
    });
  }
};

export const editRestaurant = (restaurant: Irestaurant, id: string): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: EDIT_RESTAURANT_REQUEST,
  });
  try {
    await client.editRestaurant(restaurant, id);
    dispatch({
      type: EDIT_RESTAURANT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EDIT_RESTAURANT_FAILURE,
      error: error,
    });
  }
};

export const deleteRestaurant = (id: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: DELETE_RESTAURANT_REQUEST,
  });
  try {
    await client.deleteRestaurant(id);
    dispatch({
      type: DELETE_RESTAURANT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_RESTAURANT_FAILURE,
      error: error,
    });
  }
};

export const getRestaurantTypes = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_RESTAURANT_TYPES_REQUEST,
  });
  try {
    const response = await client.fetchRestaurantTypes();
    dispatch({
      type: GET_RESTAURANT_TYPES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      error,
      type: GET_RESTAURANT_TYPES_FAILURE,
    });
  }
};

export const getRestaurantList = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_RESTAURANT_LIST_REQUEST,
  });

  try {
    const response = await client.fetchRestaurantList();
    dispatch({
      type: GET_RESTAURANT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_RESTAURANT_LIST_FAILURE,
      error,
    });
  }
};

export const getCountryList = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COUNTRY_LIST_REQUEST,
  });
  try {
    const response = await client.fetchCountryList();
    const convertedResponse = Object.keys(response.data).map((_key, index) => ({
      label: Object.values(response.data)[index],
      value: Object.values(response.data)[index],
    }));
    // Sort by value ascending
    const sortedResponse = convertedResponse.sort((a, b) =>
      a.value > b.value ? 1 : b.value > a.value ? -1 : 0,
    );
    const payload = moveHungaryToFirstPlaceInCountryList(sortedResponse);
    dispatch({
      payload,
      type: GET_COUNTRY_LIST_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error,
      type: GET_COUNTRY_LIST_FAILURE,
    });
  }
};
