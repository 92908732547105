import React, { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportParameter } from '../../../../redux/reports/interfaces';
import CheckboxWithLabel from '../../../../components/CheckboxWithLabel';
import RadioWithLabel from '../../../../components/RadioWithLabel';
import { USERTYPE } from '../../../../resources/enums';

interface ReportParametersProps {
  data: ReportParameter[];
  onRadioChange?: (value: string | USERTYPE) => void;
  onCheckboxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Renders the report parameters.
 * @param data - The report parameter array.
 */
const ReportParameters: React.FC<ReportParametersProps> = ({
  data,
  onRadioChange,
  onCheckboxChange,
}) => {
  const { t } = useTranslation();

  const renderCheckbox = (item: ReportParameter, isChildren?: boolean): ReactElement => (
    <div key={item.id} className={isChildren ? 'parameter-children' : ''}>
      <CheckboxWithLabel
        label={item.label}
        name={item.id}
        checked={item.checked}
        onChange={onCheckboxChange}
      />
    </div>
  );

  const renderRadioButton = (item: ReportParameter, isChildren?: boolean): ReactElement => (
    <div key={item.id} className={isChildren ? 'parameter-children' : ''}>
      <RadioWithLabel
        label={item.label}
        defaultChecked={item.defaultChecked}
        name={item.name}
        onChange={(): void => onRadioChange && onRadioChange(item.id)}
      />
    </div>
  );

  const renderParameters = (
    parameters: ReportParameter[],
    isChildren?: boolean,
  ): ReactElement[] => {
    let elements: ReactElement[] = [];
    //eslint-disable-next-line
    parameters.map((parameter) => {
      if (parameter.type === 'checkbox') {
        elements.push(renderCheckbox(parameter, isChildren));
      } else {
        elements.push(renderRadioButton(parameter, isChildren));
      }
      if (parameter.childrens && parameter.childrens.length) {
        elements = elements.concat(renderParameters(parameter.childrens, true));
      }
    });
    return elements;
  };

  return (
    <div className="parametersContainer">
      <div className="parameterTitle">{t('reportsScreen.common.parameters')}</div>
      {renderParameters(data)}
    </div>
  );
};

export default ReportParameters;
