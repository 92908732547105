import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect, RootStateOrAny } from 'react-redux';

interface PrivateRouteProps extends RouteProps {
  isAuthenticated?: boolean;
  accessLevel?: number;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAuthenticated, ...rest }) => {
  return isAuthenticated ? <Route {...rest} /> : <Redirect to="/" />;
};

const mapStateToProps = (state: RootStateOrAny): object => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, null)(PrivateRoute);
