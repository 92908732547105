import i18next from 'i18next';
import { IcomplaintType } from '../../redux/complaints/interfaces';
import { STATUS } from './../../resources/enums';
import { Iaddress } from './../../redux/restaurants/interfaces';

/**
 *
 * @param address Address
 *
 * Converts an address object to string.
 */
export const addressToString = (address: Iaddress): string => {
  const { country, postalCode, city, streetName, streetNumber } = address;
  return `${country} ${postalCode} ${city}, ${streetName} ${streetNumber}`;
};
/**
 *
 * @param status STATUS
 *
 * Converts the status to string, with translation.
 */
export const statusToString = (status: STATUS): string => {
  return status === STATUS.ACTIVE ? i18next.t('ACTIVE') : i18next.t('INACTIVE');
};
/**
 *
 * @param status STATUS
 *
 * Converts the status to string for complaint, with translation.
 */
export const statusToStringForComplaint = (status: STATUS): string => {
  return status === STATUS.ACTIVE ? i18next.t('ACTIVE') : i18next.t('complaintListScreen.closed');
};

/**
 *
 * @param value - The complaint type's value.
 * @param complaintTypes - The complaint type lists.
 *
 * Return a complaint type's label by its value.
 */
export const getComplaintTypeLabelByValue = (
  value: string,
  complaintTypes: IcomplaintType[],
): string | undefined => {
  const complaintType =
    complaintTypes && complaintTypes.find((complaintType) => complaintType.value === value);
  return complaintType?.label;
};

/**
 * @param counrties - The country list.
 *
 * Move Hungary to the first place in the array of countries.
 */
export const moveHungaryToFirstPlaceInCountryList = (
  counrties: { label: string; value: string }[],
): { label: string; value: string }[] => {
  const hungaryElement = counrties.find((country) => country.value === 'Magyarország');
  const newList = counrties.filter((country) => country !== hungaryElement);

  if (hungaryElement) {
    newList.unshift(hungaryElement);
  }

  return newList;
};
