import { AxiosError } from 'axios';
import { Notifications, NotificationTypes } from './interfaces';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_REQUEST';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATIONS_FAILURE = 'DELETE_NOTIFICATIONS_FAILURE';

export interface IgetNotificationsRequest {
  type: typeof GET_NOTIFICATIONS_REQUEST;
}

export interface IgetNotificationsSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: Notifications;
}

export interface IgetNotificationsFailure {
  type: typeof GET_NOTIFICATIONS_FAILURE;
  error: AxiosError;
}

export interface IdeleteNotificationsRequest {
  type: typeof DELETE_NOTIFICATIONS_REQUEST;
  payload: { notificationType: NotificationTypes };
}

export interface IdeleteNotificationsSuccess {
  type: typeof DELETE_NOTIFICATIONS_SUCCESS;
  payload: Notifications;
}

export interface IdeleteNotificationsFailure {
  type: typeof DELETE_NOTIFICATIONS_FAILURE;
  error: AxiosError;
}

export type NotificationActionTypes =
  | IgetNotificationsFailure
  | IgetNotificationsRequest
  | IgetNotificationsSuccess
  | IdeleteNotificationsRequest
  | IdeleteNotificationsSuccess
  | IdeleteNotificationsFailure;
