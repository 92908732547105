import { TFunction } from 'i18next';
import { IuserFormObject, IuserErrorObject } from '../../redux/users/interfaces';
import {
  IrestaurantFormObject,
  IrestaurantErrorObject,
  IbusinessHourObject,
} from '../../redux/restaurants/interfaces';
import { STATUS } from '../../resources/enums';
import { IuserAuth } from '../../redux/authentication/interfaces';
import { restaurantInitError } from './../../resources/constants';

export const validateLoginForm = (
  form: IuserAuth,
  setErrors: React.Dispatch<React.SetStateAction<IuserAuth>>,
  t: TFunction,
): boolean => {
  let isValid = true;
  const errors = {
    email: '',
    password: '',
  };
  if (!form.email) {
    errors.email = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (!form.password) {
    errors.password = t('formValidation.requiredField');
    isValid = false;
  }
  setErrors(errors);
  return isValid;
};

export const validateUserForm = (
  form: IuserFormObject,
  setErrors: React.Dispatch<React.SetStateAction<IuserErrorObject>>,
  t: TFunction,
): boolean => {
  let isValid = true;
  const errors = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
    type: '',
  };
  if (!form.firstName) {
    errors.firstName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.lastName) {
    errors.lastName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.email) {
    errors.email = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (!form.password) {
    errors.password = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.passwordAgain) {
    errors.passwordAgain = t('formValidation.requiredField');
    isValid = false;
  }
  if (form.password && form.passwordAgain && form.password !== form.passwordAgain) {
    errors.password = t('formValidation.passwordMismatch');
    errors.passwordAgain = t('formValidation.passwordMismatch');
    isValid = false;
  }
  setErrors(errors);
  return isValid;
};

export const validateRestaurantForm = (
  form: IrestaurantFormObject,
  setErrors: React.Dispatch<React.SetStateAction<IrestaurantErrorObject>>,
  t: TFunction,
  isAddRestaurant: boolean,
): boolean => {
  let isValid = true;
  const errors = restaurantInitError;
  if (!form.name) {
    errors.name = t('formValidation.requiredField');
    isValid = false;
  }
  if (form.address.country === t('addRestaurantScreen.country') || !form.address.country) {
    errors.country = t('formValidation.chooseValue');
    isValid = false;
  }
  if (!form.address.city) {
    errors.city = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.address.postalCode) {
    errors.postalCode = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.address.streetName) {
    errors.streetName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.address.streetNumber) {
    errors.streetNumber = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.email) {
    errors.email = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (!form.password && isAddRestaurant) {
    errors.password = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.passwordAgain && isAddRestaurant) {
    errors.passwordAgain = t('formValidation.requiredField');
    isValid = false;
  }
  if (form.password !== form.passwordAgain) {
    errors.password = t('formValidation.passwordMismatch');
    errors.passwordAgain = t('formValidation.passwordMismatch');
    isValid = false;
  }
  if (!form.restaurantType.length) {
    errors.restaurantType = t('formValidation.chooseValue');
    isValid = false;
  }
  if (!form.firstName) {
    errors.firstName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.lastName) {
    errors.lastName = t('formValidation.requiredField');
    isValid = false;
  }
  if (form.status !== STATUS.ACTIVE && form.status !== STATUS.INACTIVE) {
    errors.status = t('formValidation.chooseValue');
    isValid = false;
  }
  if (!form.businessHours.some((e) => e.from && e.to) && form.status === STATUS.ACTIVE) {
    errors.businessHours = t('formValidation.noHoursSelected');
    isValid = false;
  }
  if (!form.companyName) {
    errors.companyName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.companyAddress) {
    errors.companyAddress = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.taxNumber) {
    errors.taxNumber = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.bankAccountNumber) {
    errors.bankAccountNumber = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.contactPhone) {
    errors.contactPhone = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.contactEmail) {
    errors.contactEmail = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.contactEmail)) {
    errors.contactEmail = t('formValidation.emailInvalid');
    isValid = false;
  }
  setErrors(errors);
  return isValid;
};

export const validateBusinessHours = (businessHours: IbusinessHourObject[]): boolean => {
  let hasError = false;
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  businessHours.forEach((bh): void => {
    if (bh.to || bh.from) {
      const from = bh.from.split(':').map((e) => parseInt(e));
      const to = bh.to.split(':').map((e) => parseInt(e));
      if (from[0] > to[0] || (from[0] === to[0] && from[1] >= to[1])) {
        hasError = true;
      }
      if (!regex.test(bh.from) || !regex.test(bh.to)) {
        hasError = true;
      }
    }
  });
  return hasError;
};

export const validateColleagueForm = (
  form: IuserFormObject,
  setErrors: React.Dispatch<React.SetStateAction<IuserErrorObject>>,
  t: TFunction,
): boolean => {
  let isValid = true;
  const errors = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
  };
  if (!form.firstName) {
    errors.firstName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.lastName) {
    errors.lastName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.email) {
    errors.email = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (!form.password) {
    errors.password = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.passwordAgain) {
    errors.passwordAgain = t('formValidation.requiredField');
    isValid = false;
  }
  if (form.password && form.passwordAgain && form.password !== form.passwordAgain) {
    errors.password = t('formValidation.passwordMismatch');
    errors.passwordAgain = t('formValidation.passwordMismatch');
    isValid = false;
  }
  setErrors(errors);
  return isValid;
};

export const validateEditColleague = (
  form: IuserFormObject,
  setErrors: React.Dispatch<React.SetStateAction<IuserErrorObject>>,
  t: TFunction,
): boolean => {
  let isValid = true;
  const errors = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
  };
  if (!form.firstName) {
    errors.firstName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.lastName) {
    errors.lastName = t('formValidation.requiredField');
    isValid = false;
  }
  if (!form.email) {
    errors.email = t('formValidation.requiredField');
    isValid = false;
  } else if (!/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (form.email && !/\S+@\S+\.\S+/.test(form.email)) {
    errors.email = t('formValidation.emailInvalid');
    isValid = false;
  }
  if (form.password !== form.passwordAgain) {
    errors.password = t('formValidation.passwordMismatch');
    errors.passwordAgain = t('formValidation.passwordMismatch');
    isValid = false;
  }
  setErrors(errors);
  return isValid;
};
