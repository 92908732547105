import React, { useEffect, ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../redux/store';
import {
  getRestaurants,
  getRestaurantsNextPage,
  resetRestaurantsList,
} from '../../redux/restaurants/restaurants.actions';
import { Loader, Table, Button, SearchBar } from '../../components';
import { addressToString } from '../../utilities/functions/helperFunctions';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { usePageBottomReachedEffect } from '../../hooks/usePageBottomReachedEffect';
import { clearNotifications } from '../../redux/notifications/notifications.actions';
import { NotificationTypes } from '../../redux/notifications/interfaces';
import { useFetchOnHighRes } from '../../hooks/useFetchOnHighRes';

const RestaurantsListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const restaurants = useSelector((state: AppState) => state.restaurants.restaurants);
  const { isLoading, reachedLastPage } = useSelector((state: AppState) => state.restaurants);
  const [searchValue, setSearchValue] = useState('');
  const tableId = 'restaurantListTable';

  // Fetch restaurants
  useEffect(() => {
    dispatch(resetRestaurantsList());
    dispatch(getRestaurants());
    dispatch(clearNotifications(NotificationTypes.restaurants));
  }, [dispatch]);

  usePageBottomReachedEffect((): void => {
    dispatch(getRestaurantsNextPage(searchValue));
  });

  useFetchOnHighRes((): void => {
    if (!reachedLastPage && restaurants) {
      dispatch(getRestaurantsNextPage(searchValue));
    }
  }, tableId);

  const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setSearchValue(event.currentTarget.value);
      dispatch(resetRestaurantsList());
      dispatch(getRestaurants(event.currentTarget.value));
    }
  };

  const tableHeader = [t('name'), t('restaurantListScreen.address'), t('status')];

  const navigateToEditRestaurant = (id: string | undefined): unknown =>
    history.push({ pathname: '/edit_restaurants', search: `id=${id}` });

  const navigateToAddRestaurant = (): unknown => history.push('add_restaurant');

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderSearchBar = (): ReactElement => {
    return <SearchBar onKeyPress={handleSearchKeyPress} />;
  };

  const renderTable = (): ReactElement => {
    return (
      <>
        <Table
          id={tableId}
          header={tableHeader}
          title={t('navbar.restaurantsTitle')}
          actionButtonText={t('restaurantListScreen.addRestaurantsButton')}
          actionButtonClick={navigateToAddRestaurant}
        >
          {restaurants.map(({ _id, name, address, status }) => {
            return (
              <tr key={_id}>
                <td>{name}</td>
                <td>{addressToString(address)}</td>
                <td>{status}</td>
                <td className="has-text-centered">
                  <Button
                    onClick={(): unknown => _id && navigateToEditRestaurant(_id)}
                    className="is-small"
                  >
                    {t('edit')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>
        {renderLoading()}
      </>
    );
  };

  return (
    <div className="container">
      {renderSearchBar()}
      {restaurants && renderTable()}
    </div>
  );
};

export default withErrorMessage(RestaurantsListScreen);
