import { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { store } from '../redux/store';
import { refreshAccessToken, logOutUser } from '../redux/authentication/authentication.actions';
import { resetError } from '../redux/error/error.action';
import i18n from '../utilities/i18n';
import axiosInstance, { axiosFormDataInstance } from './axiosInstance';
import { REFRESH_TOKEN_URL, BASE_URL } from './urls';

interface ExtendedRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

let tokenRefreshing = false;
let subscribers: ((accessToken: string) => void)[] = [];

const onAccessTokenFetched = (): void => {
  tokenRefreshing = false;
  const { accessToken } = store.getState().auth;
  subscribers.map((callback) => callback(accessToken));
  subscribers = [];
};

/**
 *
 * @param callback function that will subscribe to token refresh success
 */
const addSubscriber = (callback: (accessToken: string) => void): void => {
  subscribers.push(callback);
};

/**
 *
 * @param error error of the failed api request
 * @param axios instance of axios
 *
 * resets token and reruns all requests that happened during the refresh
 */
const resetTokenAndReattemptRequest = async (
  error: AxiosError,
  axios: AxiosInstance,
): Promise<AxiosResponse | void | unknown> => {
  const originalRequest: ExtendedRequestConfig = error.config;
  const { response } = error;
  if (response && response.status && response.status === 401 && !originalRequest._retry) {
    const { refreshToken } = store.getState().auth;
    if (
      !refreshToken ||
      originalRequest.url === `${BASE_URL}/${REFRESH_TOKEN_URL}` ||
      originalRequest._retry
    ) {
      store.dispatch<any>(logOutUser());
      store.dispatch<any>(resetError());
      return Promise.reject(error);
    }

    originalRequest._retry = true;
    store.dispatch<any>(resetError());
    if (!tokenRefreshing) {
      tokenRefreshing = true;
      store.dispatch<any>(refreshAccessToken(refreshToken, onAccessTokenFetched));
    }

    const retryOrigReq = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }
  return Promise.reject(error);
};

const setMessage = async (error: AxiosError): Promise<void> =>
  new Promise((resolve) => {
    // Object is possibly undefined workaround TS <3
    const { response = { data: { message: i18n.t('errorMessages.500') }, status: 500 } } = error;
    response.data.message = i18n.t([`errorMessages.${response.status}`, 'errorMessages.500']);
    resolve();
  });

const setAxiosInterceptors = (): void => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (response.status === 401 && response.config.url !== 'login') {
        return resetTokenAndReattemptRequest(error, axiosInstance);
      } else if (response) {
        await setMessage(error);
        return Promise.reject(error);
      }
    },
  );
  axiosFormDataInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (response.status === 401) {
        return resetTokenAndReattemptRequest(error, axiosFormDataInstance);
      } else if (response) {
        await setMessage(error);
        return Promise.reject(error);
      }
    },
  );
};

export default setAxiosInterceptors;
