import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { hu } from './locales';

const resources = {
  hu: {
    translation: hu,
  },
};

const languageDetector = new LanguageDetector();

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init(
    {
      resources,
      lng: 'hu',
      fallbackLng: 'hu',
      interpolation: {
        escapeValue: false,
      },
    },
    () => i18n.language,
  );
export default i18n;
