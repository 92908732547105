import React, { useState, useRef, useEffect } from 'react';
import { CheckboxWithLabel } from '..';

export interface IinputWithDropdownItem {
  label: string;
  value: string;
}

interface InputWithDropdownProps {
  onChange: (selectedOptions: string[]) => void;
  className?: string | null;
  dropdownContent: IinputWithDropdownItem[];
  value: string[];
  placeholder?: string;
  multiple?: boolean;
  numberOfSeparatedItems?: number;
  error?: string;
}

/**
 * Input with dropdown component.
 *
 * @param onChange - A function what will be called when changed the selected item(s).
 * @param className - The input's custom className.
 * @param dropdownContent - The input's option array.
 * @param value - The selected item(s).
 * @param placeholder - The placeholder text.
 * @param multiple - If true, the selecting multiple items is possible.
 * @param numberOfSeparatedItems - Separate the first X option in the dropdown.
 * @param error - The error text.
 */
const InputWithDropdown: React.FC<InputWithDropdownProps> = ({
  className,
  value,
  onChange,
  dropdownContent,
  multiple,
  placeholder,
  numberOfSeparatedItems,
  error,
}) => {
  const [listOpen, setListOpen] = useState<boolean>(false);
  // If not as ... typescripts thinks it's undefined...
  const node = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickOutside = ({ target }: Event): void => {
    if (node.current.contains(target as Node)) {
      // inside click
      return;
    }
    // outside click
    setListOpen(false);
  };

  useEffect(() => {
    if (listOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    //eslint-disable-next-line
  }, [listOpen]);

  //For multiselect dropdown
  const toggleItemSelected = (item: string): void => {
    if (value.includes(item)) {
      onChange(value.filter((element) => element !== item));
    } else {
      onChange([...value, item]);
    }
  };

  //For single select dropdown
  const changeSelected = (content: string): void => {
    onChange([content]);
    setListOpen(false);
  };

  const getLabeledValues = (): string[] => {
    return value.map((item) => {
      const result = dropdownContent.find((content) => content.value === item);

      return result?.label || '';
    });
  };

  const DividerOrNullInDropdownListCallback = React.useCallback(
    (index: number): React.ReactElement | null => {
      return numberOfSeparatedItems && index === numberOfSeparatedItems - 1 ? (
        <div className="divider" />
      ) : null;
    },
    [numberOfSeparatedItems],
  );

  return (
    <div className="field">
      <div className="control">
        <div className={`input ${className}`} ref={node}>
          <div
            className="dropdown-label"
            onClick={(): void => setListOpen((prevState) => !prevState)}
          >
            {value[0] ? getLabeledValues().toString() : placeholder}
            <span className="material-icons dropdownIcon">
              {listOpen ? 'expand_less' : 'expand_more'}
            </span>
          </div>
          {listOpen && (
            <div className="dropdownList box">
              {dropdownContent.map((content, index) => (
                <div key={index}>
                  <div>
                    {multiple ? (
                      <CheckboxWithLabel
                        label={content.label}
                        checked={value.includes(content.value)}
                        onChange={(): void => toggleItemSelected(content.value)}
                      />
                    ) : (
                      <div onClick={(): void => changeSelected(content.value)}>{content.label}</div>
                    )}
                  </div>
                  {DividerOrNullInDropdownListCallback(index)}
                </div>
              ))}
            </div>
          )}
        </div>
        {error && <p className="validationError">{error}</p>}
      </div>
    </div>
  );
};

export default InputWithDropdown;
