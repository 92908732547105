import client from '../../api/client';
import { AppState, AppThunk } from './../store/index';

import {
  GET_COMPLAINT_LIST_REQUEST,
  GET_COMPLAINT_LIST_SUCCESS,
  RESET_COMPLAINT_LIST,
  GET_COMPLAINT_BY_ID_REQUEST,
  GET_COMPLAINT_BY_ID_SUCCESS,
  GET_COMPLAINT_BY_ID_FAILURE,
  CLOSE_COMPLAINT_REQUEST,
  CLOSE_COMPLAINT_SUCCESS,
  CLOSE_COMPLAINT_FAILURE,
  GET_COMPLAINT_LIST_FAILURE,
  GET_COMPLAINT_TYPES_SUCCESS,
  GET_COMPLAINT_TYPES_REQUEST,
  GET_COMPLAINT_TYPES_FAILURE,
  COMPLAINT_LIST_LAST_PAGE_REACHED,
  GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS,
} from './types';

export const getComplaintList = (searchValue?: string): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: GET_COMPLAINT_LIST_REQUEST,
  });
  try {
    const res = await client.fetchComplaints(0, searchValue);
    dispatch({
      type: GET_COMPLAINT_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      error,
      type: GET_COMPLAINT_LIST_FAILURE,
    });
  }
};

export const getComplaintsNextPage = (searchValue?: string): AppThunk => async (
  dispatch,
  getState: () => AppState,
): Promise<void> => {
  const isLoading = getState().complaints.isLoading;
  if (isLoading) {
    return;
  }

  const reachedLastPage = getState().complaints.reachedLastPage;
  if (!reachedLastPage) {
    dispatch({
      type: GET_COMPLAINT_LIST_REQUEST,
    });

    try {
      const nextPage = getState().complaints.page + 1;
      const res = await client.fetchComplaints(nextPage, searchValue);
      if (res.data.length === 0) {
        dispatch({
          type: COMPLAINT_LIST_LAST_PAGE_REACHED,
        });
      } else {
        dispatch({
          type: GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS,
          payload: {
            complaintList: res.data,
            page: nextPage,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COMPLAINT_LIST_FAILURE,
        error: error,
      });
    }
  }
};

export const getComplaintById = (id: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COMPLAINT_BY_ID_REQUEST,
  });
  try {
    const res = await client.fetchComplaint(id);
    dispatch({
      type: GET_COMPLAINT_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      error,
      type: GET_COMPLAINT_BY_ID_FAILURE,
    });
  }
};

export const closeComplaint = (id: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: CLOSE_COMPLAINT_REQUEST,
  });
  try {
    await client.closeComplaint(id);
    dispatch({
      type: CLOSE_COMPLAINT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error,
      type: CLOSE_COMPLAINT_FAILURE,
    });
  }
};

export const resetComplaintList = (): AppThunk => (dispatch): void => {
  dispatch({
    type: RESET_COMPLAINT_LIST,
  });
};

export const getComplaintTypes = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COMPLAINT_TYPES_REQUEST,
  });
  try {
    const res = await client.fetchComplaintTypes();
    dispatch({
      type: GET_COMPLAINT_TYPES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      error,
      type: GET_COMPLAINT_TYPES_FAILURE,
    });
  }
};
