import React from 'react';
import Button from '../Button';

type TableHeader = string[];

interface TableProps {
  id?: string;
  header: TableHeader;
  title: string;
  actionButtonText?: string;
  actionButtonClick?: () => unknown;
}

/**
 * Plase use table classes from bulma. It's highly customizable.
 * https://bulma.io/documentation/elements/table/
 */

const Table: React.FC<TableProps> = ({
  header,
  children,
  title,
  actionButtonText,
  actionButtonClick,
  id,
}) => {
  return (
    <div id={id} className="box tableContainer">
      <div className="tableHeading">
        <h1>{title}</h1>
        {actionButtonText && (
          <Button onClick={actionButtonClick} className="is-medium navigateButton">
            {actionButtonText}
          </Button>
        )}
      </div>
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr className="is-bold">
            {header.map((element) => (
              <th key={element}>{element}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
