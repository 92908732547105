import { AxiosError } from 'axios';
import { Iuser } from './interfaces';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_NEXT_PAGE_SUCCESS = 'GET_USERS_NEXT_PAGE_SUCCESS';
export const GET_USERS_LAST_PAGE_REACHED = 'GET_USERS_LAST_PAGE_REACHED';
export const RESET_USERS_LIST = 'RESET_USERS_LIST';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const TOGGLE_ACTIVATE_USER_REQUEST = 'TOGGLE_ACTIVATE_USER_REQUEST';
export const TOGGLE_ACTIVATE_USER_SUCCESS = 'TOGGLE_ACTIVATE_USER_SUCCESS';
export const TOGGLE_ACTIVATE_USER_FAILURE = 'TOGGLE_ACTIVATE_USER_FAILURE';

export interface ItoggleACtivateUserRequest {
  type: typeof TOGGLE_ACTIVATE_USER_REQUEST;
}

export interface ItoggleACtivateUserSuccess {
  type: typeof TOGGLE_ACTIVATE_USER_SUCCESS;
}

export interface ItoggleACtivateUserFailure {
  type: typeof TOGGLE_ACTIVATE_USER_FAILURE;
  error: AxiosError;
}

export interface IgetUsersRequestAction {
  type: typeof GET_USERS_REQUEST;
}

export interface IgetUsersSuccessAction {
  type: typeof GET_USERS_SUCCESS;
  payload: Iuser[];
}

export interface IgetUsersFailureAction {
  type: typeof GET_USERS_FAILURE;
  error: AxiosError;
}
export interface IaddUserRequestAction {
  type: typeof ADD_USER_REQUEST;
}

export interface IaddUsersSuccessAction {
  type: typeof ADD_USER_SUCCESS;
}

export interface IaddUserFailureAction {
  type: typeof ADD_USER_FAILURE;
  error: AxiosError;
}

export interface IgetUsersNextPageSuccessAction {
  type: typeof GET_USERS_NEXT_PAGE_SUCCESS;
  payload: Iuser[];
  page: number;
}

export interface IgetUsersLastPageReachedAction {
  type: typeof GET_USERS_LAST_PAGE_REACHED;
}

export interface IresetUsersList {
  type: typeof RESET_USERS_LIST;
}

export interface IgetUserRequestAction {
  type: typeof GET_USER_REQUEST;
}

export interface IgetUserSuccessAction {
  type: typeof GET_USER_SUCCESS;
  payload: Iuser;
}

export interface IgetUserFailureAction {
  type: typeof GET_USER_FAILURE;
  error: AxiosError;
}

export type UsersActionTypes =
  | IgetUsersRequestAction
  | IgetUsersSuccessAction
  | IgetUsersFailureAction
  | IaddUserRequestAction
  | IaddUsersSuccessAction
  | IaddUserFailureAction
  | IgetUsersNextPageSuccessAction
  | IgetUsersLastPageReachedAction
  | IresetUsersList
  | IgetUserRequestAction
  | IgetUserSuccessAction
  | IgetUserFailureAction
  | ItoggleACtivateUserFailure
  | ItoggleACtivateUserRequest
  | ItoggleACtivateUserSuccess;
