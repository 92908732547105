import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderStatisticProps {
  labelKey: string;
  value: number;
}

/**
 * One line header statistic for activity report, when the selected activtiy type is restaurant or user.
 * @param labelKey - The statistic's label key.
 * @param value - The statistic's value.
 */
const HeaderStatistic: React.FC<HeaderStatisticProps> = ({ labelKey, value }) => {
  const { t } = useTranslation();

  return (
    <div className="headerStatistic">
      <div>{t(labelKey)}:</div>
      <div className="boldedText">{value}</div>
    </div>
  );
};

export default HeaderStatistic;
