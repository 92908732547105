import { useCallback, useEffect } from 'react';

/**
 *
 * @param onPageBottomReachedCallback - this function will be called when the user scrolls to the bottom of the page.
 */
export const usePageBottomReachedEffect = (onPageBottomReachedCallback: () => void): void => {
  const scrollHandler = useCallback((): void => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      onPageBottomReachedCallback();
    }
  }, [onPageBottomReachedCallback]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return (): void => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);
};
