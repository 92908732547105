import { defaultRestaurant } from '../../resources/constants';
import {
  RestaurantActionTypes,
  ADD_RESTAURANT_REQUEST,
  ADD_RESTAURANT_SUCCESS,
  ADD_RESTAURANT_FAILURE,
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANTS_NEXT_PAGE_SUCCESS,
  GET_RESTAURANTS_LAST_PAGE_REACHED,
  RESET_RESTAURANTS_LIST,
  GET_RESTAURANT_SUCCESS,
  GET_RESTAURANT_REQUEST,
  GET_RESTAURANT_FAILURE,
  EDIT_RESTAURANT_REQUEST,
  EDIT_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT_FAILURE,
  GET_RESTAURANT_TYPES_SUCCESS,
  GET_RESTAURANT_TYPES_REQUEST,
  GET_RESTAURANT_TYPES_FAILURE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_SUCCESS,
  GET_RESTAURANT_LIST_FAILURE,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_SUCCESS,
} from './types';
import { IrestaurantState } from './interfaces';

const defaultRestaurantsState: IrestaurantState = {
  isLoading: false,
  restaurants: [],
  errorMessage: null,
  page: 0,
  reachedLastPage: false,
  restaurant: defaultRestaurant,
  restaurantTypes: [],
  countryList: [],
  restaurantNames: [],
};

export default (
  state = defaultRestaurantsState,
  action: RestaurantActionTypes,
): IrestaurantState => {
  switch (action.type) {
    case ADD_RESTAURANT_REQUEST:
    case GET_RESTAURANTS_REQUEST:
    case GET_RESTAURANT_REQUEST:
    case GET_RESTAURANT_TYPES_REQUEST:
    case EDIT_RESTAURANT_REQUEST:
    case GET_COUNTRY_LIST_REQUEST:
    case GET_RESTAURANT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        restaurant: defaultRestaurant,
      };
    case EDIT_RESTAURANT_SUCCESS:
    case ADD_RESTAURANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case GET_RESTAURANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        restaurants: action.payload,
      };
    case GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        restaurant: action.payload,
      };
    case ADD_RESTAURANT_FAILURE:
    case GET_RESTAURANTS_FAILURE:
    case GET_RESTAURANT_FAILURE:
    case GET_RESTAURANT_TYPES_FAILURE:
    case EDIT_RESTAURANT_FAILURE:
    case GET_COUNTRY_LIST_FAILURE:
    case GET_RESTAURANT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.response?.data.message,
      };
    case GET_RESTAURANTS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        restaurants: [...state.restaurants, ...action.payload],
        page: action.page,
      };
    case GET_RESTAURANTS_LAST_PAGE_REACHED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        reachedLastPage: true,
      };
    case GET_RESTAURANT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        restaurantTypes: action.payload,
      };
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        countryList: action.payload,
      };
    case GET_RESTAURANT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        restaurantNames: action.payload,
      };
    case RESET_RESTAURANTS_LIST:
      return {
        ...defaultRestaurantsState,
      };
    default:
      return state;
  }
};
