import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/store';
import { IrestaurantType } from '../../redux/restaurants/interfaces';
import { getRestaurantTypes } from '../../redux/restaurants/restaurants.actions';

/**
 * Hook for load the restaurant types.
 */
const useRestaurantTypes = (): [IrestaurantType[]] => {
  const { restaurantTypes } = useSelector((state: AppState) => state.restaurants);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!restaurantTypes.length) {
      dispatch(getRestaurantTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [restaurantTypes];
};

export default useRestaurantTypes;
