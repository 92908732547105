import React, { ReactElement, useState, FormEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput, Loader } from '../../components';
import { AppState } from '../../redux/store';
import * as actions from '../../redux/colleagues/colleagues.actions';
import { IuserFormObject, IuserErrorObject } from '../../redux/users/interfaces';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { validateColleagueForm } from '../../utilities/functions/validateFunctions';
import { initialFormState } from '../../resources/constants';

const AddColleagueScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);
  const isLoading = useSelector((state: AppState) => state.colleagues.isLoading);
  const initialErrorState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
  };
  const [form, setForm] = useState<IuserFormObject>(initialFormState);
  const [errors, setErrors] = useState<IuserErrorObject>(initialErrorState);
  const firstUpdate = useRef(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!isLoading && !errorMessage && success) {
      setSuccess(false);
      history.push('/colleagues_list');
    }
  }, [isLoading, errorMessage, history, success]);

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (validateColleagueForm(form, setErrors, t)) {
      dispatch(actions.addColleague(form));
      setSuccess(true);
    }
  };

  const onReset = (): void => {
    setForm(initialFormState);
    setErrors(initialErrorState);
    history.push('/colleagues_list');
  };

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderForm = (): ReactElement => {
    return (
      <>
        <form noValidate onSubmit={onSubmit} onReset={onReset} className="addformContainer">
          <div className="addScreenHeader">{t('addColleagueScreen.addColleague')}</div>
          <div className="columns">
            <div className="column is-half addFormColumn">
              <TextInput
                onChange={updateField}
                value={form.firstName}
                className={`is-medium ${errors.firstName && 'red-border'}`}
                placeholder={t('firstName')}
                type="text"
                required
                name="firstName"
                error={errors.firstName}
              />
              <TextInput
                onChange={updateField}
                value={form.lastName}
                className={`is-medium ${errors.lastName && 'red-border'}`}
                placeholder={t('lastName')}
                type="text"
                required
                name="lastName"
                error={errors.lastName}
              />
            </div>
            <div className="column is-half addFormColumn">
              <TextInput
                onChange={updateField}
                value={form.email}
                className={`is-medium ${errors.email && 'red-border'}`}
                placeholder={t('email')}
                type="email"
                required
                name="email"
                error={errors.email}
              />
              <TextInput
                onChange={updateField}
                value={form.password}
                className={`is-medium ${errors.password && 'red-border'}`}
                placeholder={t('password')}
                type="password"
                required
                name="password"
                error={errors.password}
                autoComplete="off"
              />
              <TextInput
                onChange={updateField}
                value={form.passwordAgain}
                className={`is-medium ${errors.passwordAgain && 'red-border'}`}
                placeholder={t('passwordAgain')}
                type="password"
                required
                name="passwordAgain"
                error={errors.passwordAgain}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="addScreenButtonsContainer">
            <Button className="disabledColor addScreenButtons is-medium" type="reset">
              {t('resetChanges')}
            </Button>
            <Button className="addScreenButtons is-medium" type="submit">
              {t('saveChanges')}
            </Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="container">
      <div className="box addScreenContainer">
        {renderForm()}
        {renderLoading()}
      </div>
    </div>
  );
};

export default withErrorMessage(AddColleagueScreen);
