import React, { useMemo, useState, useCallback } from 'react';
import { Chart } from 'react-charts';
import moment from 'moment';
import { ChartCoordinate, ChartProps } from '../../resources/interfaces';
import InputWithDropDown, { IinputWithDropdownItem } from '../InputWithDropdown';

enum CartElementTypeEnum {
  LINE = 'line',
  AREA = 'area',
  BAR = 'bar',
  BUBBLE = 'bubble',
}

interface TooltipProps {
  datum: TooltipDatumInterFace;
  primaryAxis: PrimaryAxisInterface;
}

interface CustomChartProps {
  data: ChartProps[];
}

interface TooltipDatumInterFace {
  originalDatum: ChartCoordinate;
}

interface PrimaryAxisInterface {
  format(index: string | number): string;
}

const DEFAULT_TRANSITION = 'all .5s ease';
const ELEMENT_TYPE_OPTIONS: IinputWithDropdownItem[] = [
  { value: CartElementTypeEnum.LINE, label: CartElementTypeEnum.LINE },
  { value: CartElementTypeEnum.AREA, label: CartElementTypeEnum.AREA },
  { value: CartElementTypeEnum.BAR, label: CartElementTypeEnum.BAR },
  { value: CartElementTypeEnum.BUBBLE, label: CartElementTypeEnum.BUBBLE },
];

/**
 * Custom chart component with selectable chart type for reports.
 * Displayed on the new registrations report screen.
 * @param data - The cart's data.
 */
const CustomChart: React.FC<CustomChartProps> = ({ data }) => {
  const [defaultElementType] = ELEMENT_TYPE_OPTIONS;
  const [elementType, setElementType] = useState<CartElementTypeEnum>(
    defaultElementType.value as CartElementTypeEnum,
  );
  const memoizedData = useMemo(() => {
    return data.map((series) => {
      return {
        ...series,
        //add dummy data to fix barchart issue: https://github.com/tannerlinsley/react-charts/issues/58
        data: [
          { x: -1, y: null, date: null },
          ...series.data,
          { x: series.data[series.data.length - 1]?.x + 1, y: null, date: null },
        ],
      };
    });
  }, [data]);

  // calculate date values for the x axis
  const dates = useMemo(() => {
    const complaintDates: number[] = [];
    data.forEach((complaintGroup) => {
      complaintDates.push(...complaintGroup.data.map((complaint) => complaint.date));
    });
    complaintDates.sort();
    return Array.from(new Set(complaintDates));
  }, [data]);

  const series = useMemo(
    () => ({
      type: elementType,
      showPoints: elementType === CartElementTypeEnum.LINE ? true : false,
    }),
    [elementType],
  );
  const axes = useMemo(
    () => [
      {
        primary: true,
        type: 'linear',
        position: 'bottom',
        format: (index: string): string => {
          // remove decimal values and workaround dummy data
          if (+index % 1 !== 0 || !dates[+index]) return '';
          // use raw data to have control over tick format
          return moment(dates[+index]).format('MM-DD');
        },
      },
      {
        type: 'linear',
        position: 'left',
        hardMin: 0,
        hardMax:
          Math.max(
            ...data
              .map((series) => {
                return series.data.map((d) => {
                  return d.y;
                });
              })
              .flat(),
          ) + 1,
        format: (tick: string): number | string => {
          //remove , from 1000< values
          const cleanTick = tick.split(',').join('');
          //remove decimal axis ticks
          return +cleanTick % 1 === 0 ? ~~cleanTick : '';
        },
      },
    ],
    [data, dates],
  );
  const getSeriesStyle = useCallback(
    () => ({
      transition: DEFAULT_TRANSITION,
    }),
    [],
  );
  const getDatumStyle = useCallback(
    () => ({
      transition: DEFAULT_TRANSITION,
    }),
    [],
  );

  const onElementTypeChange = (value: string[]): void => {
    const [selectedType] = value;
    setElementType(selectedType as CartElementTypeEnum);
  };
  const getSecondary = React.useCallback((datum) => {
    return datum.y;
  }, []);

  const getPrimary = React.useCallback((datum) => {
    return datum.x;
  }, []);

  return (
    <div className="chartContainer">
      <div className="elementTypeSelectorContainer">
        <InputWithDropDown
          dropdownContent={ELEMENT_TYPE_OPTIONS}
          value={[elementType]}
          className="is-small is-fullwidth"
          onChange={onElementTypeChange}
        />
      </div>
      <Chart
        data={memoizedData}
        series={series}
        axes={axes}
        getSeriesStyle={getSeriesStyle}
        getDatumStyle={getDatumStyle}
        getSecondary={getSecondary}
        getPrimary={getPrimary}
        tooltip
      />
    </div>
  );
};

export default CustomChart;
