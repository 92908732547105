import React from 'react';
import { InputWithLabelProps } from '../../../../resources/interfaces';
import { TextInput } from '../../..';

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  disabled,
  value,
  callback,
  required,
  error,
  isFloating = false,
}) => {
  const fullTimeFormat = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  const validateTimeFormat = (): void => {
    if (fullTimeFormat.test(value)) {
      callback(value);
    } else {
      callback('');
    }
  };
  return (
    <>
      <TextInput
        className={`customInput ${(!fullTimeFormat.test(value) || error) && 'red-border'}`}
        placeholder="--:--"
        disabled={disabled}
        value={value}
        type="time"
        isFloating={isFloating}
        onBlur={validateTimeFormat}
        onChange={(e): void => callback(e.target.value)}
        required={required}
      />
      <div className="labelForInput">&#8209;{label}</div>
    </>
  );
};

export default InputWithLabel;
