import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
/**
 *
 * @param onKeyPress function that handles key press
 * The searchbar component is setting the parent searchValue state, and displays the current input.
 * The parent calls a selector for filtering data.
 *
 */

const SearchBar: React.FC<SearchBarProps> = ({ onKeyPress }) => {
  const { t } = useTranslation();
  return (
    <div className="searchBarContainer">
      <div className="control has-icons-left">
        <input
          type="text"
          className="input is-large"
          placeholder={t('search')}
          onKeyPress={onKeyPress}
        />
        <span className="icon is-large is-left">
          <i className="fas fa-search" />
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
