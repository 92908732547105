import { defaultUser } from '../../resources/constants';
import {
  UsersActionTypes,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
  GET_USERS_NEXT_PAGE_SUCCESS,
  GET_USERS_LAST_PAGE_REACHED,
  RESET_USERS_LIST,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  TOGGLE_ACTIVATE_USER_FAILURE,
  TOGGLE_ACTIVATE_USER_REQUEST,
  TOGGLE_ACTIVATE_USER_SUCCESS,
} from './types';
import { IuserState } from './interfaces';

const defaultUserState: IuserState = {
  isLoading: false,
  users: [],
  errorMessage: null,
  page: 0,
  reachedLastPage: false,
  user: defaultUser,
};

export default (state = defaultUserState, action: UsersActionTypes): IuserState => {
  switch (action.type) {
    case ADD_USER_REQUEST:
    case GET_USERS_REQUEST:
    case GET_USER_REQUEST:
    case TOGGLE_ACTIVATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case ADD_USER_SUCCESS:
    case TOGGLE_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: action.payload,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        user: action.payload,
      };
    case ADD_USER_FAILURE:
    case GET_USERS_FAILURE:
    case GET_USER_FAILURE:
    case TOGGLE_ACTIVATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case GET_USERS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: [...state.users, ...action.payload],
        page: action.page,
      };
    case GET_USERS_LAST_PAGE_REACHED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        reachedLastPage: true,
      };
    case RESET_USERS_LIST:
      return {
        ...defaultUserState,
      };
    default:
      return state;
  }
};
