import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface TableRowProps {
  labelKey: string;
  value?: string;
  children?: ReactElement;
}

/**
 * Table row for complaint details screen.
 * @param labelKey - The label's key.
 * @param value - The value.
 * @param children - The children if you don't want to display a single text (instead of value).
 */
const TableRow: React.FC<TableRowProps> = ({ labelKey, value, children }) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td className="rowTitle">{t(labelKey)}:</td>
      <td>{value ? value : children}</td>
    </tr>
  );
};

export default TableRow;
