import React from 'react';
import { RadioWithLabelProps } from '../../resources/interfaces';

/**
 * General radiobutton component with label.
 * @param label - The radio button's label.
 * @param checked - Set to true when the radio button is checked.
 * @param name - The radio button group's name.
 * @param defaultChecked - Set to true when the radio button is checked by default.
 * @param onChange - A function what will be called when the radio button checked property changed.
 */
const RadioWithLabel: React.FC<RadioWithLabelProps> = ({
  label,
  checked,
  name,
  defaultChecked,
  onChange,
}) => {
  return (
    <div>
      <label className="radio radioContainer">
        <input
          className="customRadio"
          type="radio"
          name={name}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
        />
        <span className="customLabel">{label}</span>
      </label>
    </div>
  );
};

export default RadioWithLabel;
