import React from 'react';
import { InputProps } from '../../resources/interfaces';

const TextInput: React.FC<InputProps> = ({
  children,
  className,
  value,
  type,
  placeholder,
  onChange,
  required,
  disabled,
  name,
  error,
  autocomplete,
  onBlur,
  hasIcon,
  isFloating = true,
}) => {
  return (
    <div className="field">
      <div className={`control ${hasIcon ? 'has-icons-right' : ''}`}>
        {isFloating ? (
          <label
            // check state the input, whether it is active then apply the class for floating label
            className={`floating-label-default ${value ? 'field-active' : ''}`}
          >
            {placeholder}
          </label>
        ) : null}
        <input
          className={`input ${isFloating ? 'floating-label' : ''} ${className}`}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          disabled={disabled}
          name={name}
          autoComplete={autocomplete}
          onBlur={onBlur}
        >
          {children}
        </input>
        {hasIcon && (
          <span className="icon is-small is-right">
            <i className="fas fa-percent" />
          </span>
        )}

        {error && <p className="validationError">{error}</p>}
      </div>
    </div>
  );
};

export default TextInput;
