import React, { useEffect, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../redux/store';
import { Loader, Table, Button, SearchBar } from '../../components';
import { usePageBottomReachedEffect } from '../../hooks/usePageBottomReachedEffect';
import {
  getColleagues,
  getColleaguesNextPage,
  resetColleaguesList,
} from '../../redux/colleagues/colleagues.actions';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { clearNotifications } from '../../redux/notifications/notifications.actions';
import { NotificationTypes } from '../../redux/notifications/interfaces';
import { useFetchOnHighRes } from '../../hooks/useFetchOnHighRes';

const ColleaguesListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { colleagues, reachedLastPage } = useSelector((state: AppState) => state.colleagues);
  const isLoading = useSelector((state: AppState) => state.colleagues.isLoading);
  const [searchValue, setSearchValue] = useState('');
  const tableId = 'colleagueListTable';
  // Fetch users
  useEffect(() => {
    dispatch(resetColleaguesList());
    dispatch(getColleagues());
    dispatch(clearNotifications(NotificationTypes.admins));
  }, [dispatch]);

  usePageBottomReachedEffect(() => {
    dispatch(getColleaguesNextPage(searchValue));
  });

  useFetchOnHighRes((): void => {
    if (!reachedLastPage && colleagues) {
      dispatch(getColleaguesNextPage(searchValue));
    }
  }, tableId);

  const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setSearchValue(event.currentTarget.value);
      dispatch(resetColleaguesList());
      dispatch(getColleagues(event.currentTarget.value));
    }
  };

  const navigateToAddColleague = (): unknown => history.push('add_colleague');

  const navigateToEditColleague = (_id: string | undefined): unknown =>
    history.push({ pathname: '/edit_colleagues', search: `id=${_id}` });

  const renderLoading = (): ReactElement => <Loader isLoading={isLoading} />;

  const renderSearchBar = (): ReactElement => <SearchBar onKeyPress={handleSearchKeyPress} />;

  const renderTable = (): ReactElement => {
    return (
      <>
        <Table
          id={tableId}
          header={[t('name'), t('email'), t('colleagueListScreen.accessLevel')]}
          title={t('navbar.colleaguesTitle')}
          actionButtonText={t('colleagueListScreen.addColleagueButton')}
          actionButtonClick={navigateToAddColleague}
        >
          {colleagues.map(({ firstName, lastName, _id, email, type }) => {
            return (
              <tr key={_id}>
                <td>{`${firstName} ${lastName}`}</td>
                <td>{email}</td>
                <td>{type}</td>
                <td>
                  <Button
                    onClick={(): unknown => navigateToEditColleague(_id)}
                    className="is-small"
                  >
                    {t('edit')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>
        {renderLoading()}
      </>
    );
  };

  return (
    <div className="container">
      {renderSearchBar()}
      {renderTable()}
    </div>
  );
};

export default withErrorMessage(ColleaguesListScreen);
