import React from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isActive: boolean;
  title: string;
  closeModal: () => void;
  onConfirm: () => void;
}

const Modal: React.FC<ModalProps> = ({ isActive, title, closeModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <footer className="modal-card-foot">
          <button className="button disabledColor" onClick={closeModal}>
            {t('cancel')}
          </button>
          <button className="button" onClick={onConfirm}>
            {t('delete')}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
