import React, { useState, FormEvent, ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, NavLink } from 'react-router-dom';
import { TextInput, Loader, Button } from '../../components';
import logo from '../../resources/images/logo.png';
import * as actions from '../../redux/authentication/authentication.actions';
import { IforgottenPasswordEmail } from '../../redux/users/interfaces';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';

const ForgottenPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);

  const { isLoading, isSuccess } = useSelector((state: AppState) => state.auth);

  const [form, setForm] = useState<IforgottenPasswordEmail>({
    email: '',
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/');
    }
  }, [isSuccess, history]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    dispatch(actions.addForgottenPasswordEmail(form.email));
  };

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderForm = (): ReactElement => {
    return (
      <div className={`box is-narrow-widescreen ${!errorMessage ? '-login' : '-login-Error'}`}>
        <div className="loginLogo">
          <img src={logo} alt="logo" />
        </div>
        <div className="loginLabel">{t('forgottenPasswordScreen.addYourEmail')}</div>
        <form noValidate onSubmit={onSubmit}>
          <TextInput
            onChange={updateField}
            value={form.email}
            className={`is-medium ${errorMessage && 'red-border'}`}
            placeholder={t('email')}
            type="email"
            required
            name="email"
          />
          <div className="field loginButton">
            <div className="control">
              <Button type="submit" className="is-medium is-fullwidth">
                {t('forgottenPasswordScreen.send')}
              </Button>
              {renderLoading()}
            </div>
          </div>
          <NavLink className="navLink" to="/">
            <p className="secondary-button">{t('forgottenPasswordScreen.backToLogin')}</p>
          </NavLink>
        </form>
      </div>
    );
  };

  return <div className={!errorMessage ? 'container' : ''}>{renderForm()}</div>;
};

export default withErrorMessage(ForgottenPasswordScreen);
