import React from 'react';
import { CheckboxWithLabelProps } from '../../resources/interfaces';

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  label,
  checked,
  onChange,
  defaultChecked,
  name,
}) => {
  return (
    <label className="checkboxContainer">
      <input
        className="customCheckbox"
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <span className="fakeCheckbox" />
      <span className="customLabel">{label}</span>
    </label>
  );
};

export default CheckboxWithLabel;
