import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILURE,
} from './types';
import { AppAction } from './../apptypes';
import { InotificationState } from './interfaces';

const initialState: InotificationState = {
  isLoading: false,
  errorMessage: null,
  users: null,
  restaurants: null,
  admins: null,
  complaints: null,
};

export default (state = initialState, action: AppAction): InotificationState => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
    case DELETE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case GET_NOTIFICATIONS_SUCCESS:
    case DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: action.payload.users,
        restaurants: action.payload.restaurants,
        admins: action.payload.admins,
        complaints: action.payload.complaints,
      };
    case GET_NOTIFICATIONS_FAILURE:
    case DELETE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    default:
      return state;
  }
};
