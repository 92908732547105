import React, { ReactElement } from 'react';
import { ActivityAllItem } from '../../../../redux/reports/interfaces';

interface ActivityAllProps {
  data: ActivityAllItem[];
}

/**
 * Renders the activity report when selected the all activity report type.
 * @param data - The all activity report data.
 */
const ActivityAll: React.FC<ActivityAllProps> = ({ data }) => {
  const getAllRows = (): ReactElement[] | null => {
    if (!data.length) {
      return null;
    }
    return data.map((item, index) => (
      <div className="row activityAllRow" key={index}>
        <div className="activityAllLabel">{item.label}:</div>
        <div>{item.value}</div>
      </div>
    ));
  };

  return <div className="activityAllContainer">{getAllRows()}</div>;
};

export default ActivityAll;
