import React from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';

export interface SelectionRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface DateIntervalPickerProps {
  selectionRange: SelectionRange[];
  maxDate?: Date;
  onChange: (range: SelectionRange[]) => void;
}

export const initialSelectionRange = [
  {
    startDate: new Date(Date.now() - 864e5), // yesterday
    endDate: new Date(),
    key: 'selection',
  },
];

/**
 * Date range picker for reports.
 * Displayed on the new registrations and activity report screens.
 * @param selectionRange - The object with the start and end date of range with selection key.
 * @param maxDate - The selectable maximum date.
 * @param onChange - A function what will be called when the range changed.
 */
const DateIntervalPicker: React.FC<DateIntervalPickerProps> = ({
  selectionRange,
  maxDate,
  onChange,
}) => {
  const onSelectionChange = (range: { selection: SelectionRange }): void => {
    const [selection] = selectionRange;
    const newSelection = selection ? [{ ...selection, ...range.selection }] : [range.selection];
    const roundedSelection = newSelection.map((range) => ({
      ...range,
      startDate: moment(range.startDate).startOf('day').toDate(),
      endDate: moment(range.endDate).endOf('day').toDate(),
    }));
    onChange(roundedSelection);
  };

  return (
    <DateRange
      ranges={selectionRange}
      maxDate={maxDate}
      onChange={onSelectionChange}
      weekStartsOn={1}
    />
  );
};

export default DateIntervalPicker;
