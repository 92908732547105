import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import { Loader, Button } from '../../components';
import { useQuery } from '../../hooks/useQuery';
import {
  getComplaintById,
  closeComplaint,
  getComplaintTypes,
} from '../../redux/complaints/complaints.actions';
import { STATUS } from '../../resources/enums';
import { getComplaintTypeLabelByValue } from '../../utilities/functions/helperFunctions';
import TableRow from './subcomponents/TableRow';

const ComplaintDetailsScreen: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, selectedComplaint, complaintTypes } = useSelector(
    (state: AppState) => state.complaints,
  );
  const [status, setStatus] = useState<STATUS | undefined>(selectedComplaint?.status);

  useEffect(() => {
    const id = query.get('id');
    dispatch(getComplaintById(id || ''));
    if (!complaintTypes.length) {
      dispatch(getComplaintTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectedComplaint && setStatus(selectedComplaint.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComplaint?.status]);

  const renderActionButtons = (): ReactElement => (
    <div className="actionButtonsContainer">
      <div>
        <Button className="is-dark" onClick={(): void => history.push('complaint_list')}>
          {t('complaintDetailsScreen.backToList')}
        </Button>
      </div>
      <div>
        {status === STATUS.ACTIVE && (
          <Button
            onClick={(): void => {
              selectedComplaint && dispatch(closeComplaint(selectedComplaint._id));
            }}
          >
            {t('complaintDetailsScreen.closeComplaint')}
          </Button>
        )}
      </div>
    </div>
  );

  const renderData = (): ReactElement => {
    return (
      <div>
        {selectedComplaint && (
          <>
            <h1 className="complaintHeader">
              {t('complaintDetailsScreen.complaint')} - #{selectedComplaint._id}
            </h1>
            <table className="table is-fullwidth">
              <tbody>
                <TableRow labelKey="firstName" value={selectedComplaint.firstName} />
                <TableRow labelKey="lastName" value={selectedComplaint.lastName} />
                <TableRow
                  labelKey="complaintDetailsScreen.dateOfOrder"
                  value={moment(selectedComplaint.orderDate).format('YYYY. MM. DD - HH:mm')}
                />
                <TableRow
                  labelKey="complaintDetailsScreen.provider"
                  value={selectedComplaint.restaurantName}
                />
                <TableRow
                  labelKey="complaintDetailsScreen.complaintCreatedDate"
                  value={moment(selectedComplaint.createdDate).format('YYYY. MM. DD - HH:mm')}
                />
                <TableRow labelKey="complaintDetailsScreen.usersEmail">
                  <a href={`mailto:${selectedComplaint.email}`}>{selectedComplaint.email}</a>
                </TableRow>
                <TableRow
                  labelKey="complaintDetailsScreen.complaintType"
                  value={getComplaintTypeLabelByValue(
                    selectedComplaint.complaintType,
                    complaintTypes,
                  )}
                />
                <TableRow
                  labelKey="complaintDetailsScreen.message"
                  value={selectedComplaint.message}
                />
              </tbody>
            </table>
            {renderActionButtons()}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="box complaintDetailsScreenContainer">
        <Loader isLoading={isLoading} />
        {renderData()}
      </div>
    </div>
  );
};

export default withErrorMessage(ComplaintDetailsScreen);
