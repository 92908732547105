import { AxiosError } from 'axios';
import { IloginResponse, IrefreshTokenResponse } from './interfaces';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST = 'ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST';
export const ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS = 'ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS';
export const ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE = 'ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE';

export interface IloginRequestAction {
  type: typeof LOGIN_REQUEST;
}

export interface IloginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: IloginResponse;
}

export interface IloginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: AxiosError;
}

export interface IlogoutRequestAction {
  type: typeof LOGOUT_REQUEST;
}

export interface IlogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export interface IlogoutFailureAction {
  type: typeof LOGOUT_FAILURE;
  error: AxiosError;
}

export interface IrefreshTokenRequestAction {
  type: typeof REFRESH_TOKEN_REQUEST;
}

export interface IrefreshTokenSuccessAction {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: IrefreshTokenResponse;
}

export interface IrefreshTokenFailureAction {
  type: typeof REFRESH_TOKEN_FAILURE;
  error: AxiosError;
}

export interface IforgottenPasswordEmailRequestAction {
  type: typeof ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST;
  payload: string;
}
export interface IforgottenPasswordEmailSuccessAction {
  type: typeof ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS;
}

export interface IforgottenPasswordEmailFailureAction {
  type: typeof ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE;
  error: AxiosError;
}

export type AuthActionTypes =
  | IloginRequestAction
  | IloginSuccessAction
  | IloginFailureAction
  | IlogoutRequestAction
  | IlogoutSuccessAction
  | IlogoutFailureAction
  | IrefreshTokenRequestAction
  | IrefreshTokenSuccessAction
  | IrefreshTokenFailureAction
  | IforgottenPasswordEmailRequestAction
  | IforgottenPasswordEmailSuccessAction
  | IforgottenPasswordEmailFailureAction;
