import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../redux/store';
import { getRestaurantList } from '../../redux/restaurants/restaurants.actions';
import { IrestaurantName } from './../../redux/restaurants/interfaces';

/**
 * Hook for load the restaurant list.
 */
const useRestaurantNames = (): [IrestaurantName[]] => {
  const { restaurantNames } = useSelector((state: AppState) => state.restaurants);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!restaurantNames.length) {
      dispatch(getRestaurantList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [restaurantNames];
};

export default useRestaurantNames;
