import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect, RootStateOrAny, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import logo from '../resources/images/logo.png';
import * as actions from '../redux/authentication/authentication.actions';
import { AppState } from '../redux/store';

interface NavBarProps {
  logOutUser?: () => {};
}

const NavBar: React.FC<NavBarProps> = ({
  logOutUser = (): void => {
    return;
  },
}) => {
  const { t } = useTranslation();
  const { restaurants, users, admins, complaints } = useSelector(
    (state: AppState) => state.notifications,
  );

  const logOut = async (): Promise<void> => {
    await logOutUser();
  };
  return (
    <div className="navContainer">
      <img className="navLogo" src={logo} alt="NavLogo" />
      <hr className="navLine" />
      <NavLink className="navLink" to="/restaurants_list">
        <div className="is-relative">
          <span className="material-icons navImg">storefront</span>
          {t('navbar.restaurantsTitle')}
          {restaurants !== null && (
            <span className={`badge is-right ${restaurants > 0 ? 'is-danger' : 'is-success'}`}>
              {restaurants}
            </span>
          )}
        </div>
      </NavLink>
      <NavLink className="navLink" to="/users_list">
        <div className="is-relative">
          <span className="material-icons navImg">person</span>
          {t('navbar.usersTitle')}
          {users !== null && (
            <span className={`badge is-right ${users > 0 ? 'is-danger' : 'is-success'}`}>
              {users}
            </span>
          )}
        </div>
      </NavLink>
      <NavLink className="navLink" to="/colleagues_list">
        <div className="is-relative">
          <span className="material-icons navImg">people_outline</span>
          {t('navbar.colleaguesTitle')}
          {admins !== null && (
            <span className={`badge is-right ${admins > 0 ? 'is-danger' : 'is-success'}`}>
              {admins}
            </span>
          )}
        </div>
      </NavLink>
      <NavLink className="navLink" to="/reports">
        <span className="material-icons navImg">equalizer</span>
        {t('navbar.reportsTitle')}
      </NavLink>
      <NavLink className="navLink" to="/complaint_list">
        <div className="is-relative">
          <span className="material-icons navImg">announcement</span>
          {t('navbar.complaintsTitle')}
          {complaints !== null && (
            <span className={`badge is-right ${complaints > 0 ? 'is-danger' : 'is-success'}`}>
              {complaints}
            </span>
          )}
        </div>
      </NavLink>
      <NavLink className="navLink" to="/" onClick={logOut}>
        <span className="material-icons navImg">exit_to_app</span>
        {t('navbar.logout')}
      </NavLink>
    </div>
  );
};

const mapStateToProps = (state: RootStateOrAny): object => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch): object => {
  const { logOutUser } = actions;
  return bindActionCreators(
    {
      logOutUser,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
