// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Auth
export const LOGIN_URL = 'login';
export const REFRESH_TOKEN_URL = 'refresh-token';

// Restaurants
export const ADD_RESTAURANT_URL = 'restaurant';
export const GET_RESTAURANTS_URL = 'admin-restaurants';
export const GET_RESTAURANT_URL = 'restaurant';
export const EDIT_RESTAURANT_URL = 'restaurant';
export const DELETE_RESTAURANT_URL = 'restaurant';
export const GET_RESTAURANT_TYPES_URL = 'restaurant-types';
export const COUNTRY_LIST_URL = 'country-list';
export const RESTAURANT_LIST_URL = 'restaurant-list';

//Users
export const ADD_USER_URL = 'user';
export const GET_USERS_URL = 'users';
export const GET_COLLEAGUES_URL = 'users';
export const ADD_COLLEAGUE_URL = 'user';
export const GET_USER_URL = 'user';
export const EDIT_USER_URL = 'user';
export const DELETE_USER_URL = 'user';

export const COLLEAGUE_USER_TYPE = 'ADMIN';
export const NORMAL_USER_TYPE = 'USER';

//reports
export const REPORTS_URL = 'report';
export const GET_RESTAURANT_ACITVITY_URL = 'restaurants-activity';
export const GET_USER_ACTIVITY_URL = 'users-activity';
export const GET_RESTAURANT_XLSX_URL = 'restaurants-report';
export const GET_COMPLAINT_XLSX_URL = 'complaint-reports-export';
export const GET_NEW_REGISTRATIONS_URL = 'registration-reports';
export const GET_COMPLAINTS_REPORTS_URL = 'complaint-reports';
export const SUMMARY_REPORT_URL = 'summary-report';
export const GET_FOOD_PARAMETERS_REPORT_URL = 'foodtype-report';

// Notifications
export const NOTIFICATIONS_URL = 'notifications';

// Forgotten password
export const FORGOTTEN_PASSWORD_URL = 'forgotten-password';

// Complaints
export const GET_COMPLAINTS_URL = 'complaints';
export const GET_COMPLAINT_URL = 'complaint';
export const CLOSE_COMPLAINT_URL = 'complaint';
export const GET_COMPLAINT_TYPES_URL = 'complaint-types';
