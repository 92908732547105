import { ThunkAction } from 'redux-thunk';
import client from '../../api/client';
import { UserEnum, Iuser } from '../users/interfaces';
import {
  GET_COLLEAGUES_REQUEST,
  GET_COLLEAGUES_SUCCESS,
  GET_COLLEAGUES_FAILURE,
  GET_COLLEAGUES_NEXT_PAGE_SUCCESS,
  GET_COLLEAGUES_LAST_PAGE_REACHED,
  RESET_COLLEAGUES_LIST,
  ADD_COLLEAGUE_REQUEST,
  ADD_COLLEAGUE_SUCCESS,
  ADD_COLLEAGUE_FAILURE,
  EDIT_COLLEAGUE_REQUEST,
  EDIT_COLLEAGUE_SUCCESS,
  EDIT_COLLEAGUE_FAILURE,
  GET_COLLEAGUE_REQUEST,
  GET_COLLEAGUE_SUCCESS,
  GET_COLLEAGUE_FAILURE,
  DELETE_COLLEAGUE_REQUEST,
  DELETE_COLLEAGUE_SUCCESS,
  DELETE_COLLEAGUE_FAILURE,
} from './types';
import { AppAction } from './../apptypes';
import { AppState } from './../store/index';

export const getColleagues = (
  searchValue?: string,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COLLEAGUES_REQUEST,
  });

  try {
    const res = await client.fetchColleagues(0, searchValue);
    dispatch({
      type: GET_COLLEAGUES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COLLEAGUES_FAILURE,
      error: error,
    });
  }
};

export const getColleaguesNextPage = (
  searchValue?: string,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch, getState): Promise<void> => {
  const isLoading = getState().colleagues.isLoading;
  if (isLoading) {
    return;
  }

  const reachedLastPage = getState().colleagues.reachedLastPage;
  if (!reachedLastPage) {
    dispatch({
      type: GET_COLLEAGUES_REQUEST,
    });

    try {
      const nextPage = getState().colleagues.page + 1;
      const res = await client.fetchColleagues(nextPage, searchValue);

      if (res.data.length === 0) {
        dispatch({
          type: GET_COLLEAGUES_LAST_PAGE_REACHED,
        });
      } else {
        dispatch({
          type: GET_COLLEAGUES_NEXT_PAGE_SUCCESS,
          payload: res.data,
          page: nextPage,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COLLEAGUES_FAILURE,
        error: error,
      });
    }
  }
};

export const resetColleaguesList = (): AppAction => ({ type: RESET_COLLEAGUES_LIST });

export const addColleague = (
  colleague: Iuser,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  const admin = { ...colleague, type: UserEnum.ADMIN };
  dispatch({
    type: ADD_COLLEAGUE_REQUEST,
  });
  try {
    await client.addColleague(admin);
    dispatch({
      type: ADD_COLLEAGUE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_COLLEAGUE_FAILURE,
      error: error,
    });
  }
};

export const editColleague = (
  id: string | undefined,
  colleague: Iuser,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: EDIT_COLLEAGUE_REQUEST,
  });

  try {
    await client.editUser(id, colleague);
    dispatch({
      type: EDIT_COLLEAGUE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EDIT_COLLEAGUE_FAILURE,
      error,
    });
  }
};

export const getColleague = (
  id: string | undefined,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COLLEAGUE_REQUEST,
  });

  try {
    const res = await client.fetchColleague(id);
    dispatch({
      type: GET_COLLEAGUE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COLLEAGUE_FAILURE,
      error: error,
    });
  }
};

export const deleteColleague = (
  id: string | undefined,
): ThunkAction<void, AppState, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: DELETE_COLLEAGUE_REQUEST,
  });

  try {
    await client.deleteUser(id);
    dispatch({
      type: DELETE_COLLEAGUE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COLLEAGUE_FAILURE,
      error,
    });
  }
};
