export interface InotificationResponse {
  data: Notifications;
  message: string;
}

export enum NotificationTypes {
  users = 'users',
  admins = 'admins',
  restaurants = 'restaurants',
  complaints = 'complaints',
}

export interface InotificationDeleteResponse {
  message: string;
  data: {
    _id: string;
    type: string;
    users: number;
    admins: number;
    restaurants: number;
    complaints: number;
  };
}

export interface InotificationState extends Notifications {
  isLoading: boolean;
  errorMessage: string | null;
}

export interface Notifications {
  restaurants: number | null;
  users: number | null;
  admins: number | null;
  complaints: number | null;
}
