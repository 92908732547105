import { useEffect } from 'react';

/**
 *
 * @param fetchCallback - this function will be called when there is more data, and you can't scroll to the bottom of the page.
 */

export const useFetchOnHighRes = (fetchCallback: () => void, id: string): void => {
  const element = document.getElementById(id);
  const height = element?.clientHeight || 0;

  useEffect(() => {
    if (height <= window.innerHeight && height !== 0) {
      fetchCallback();
    }
  }, [fetchCallback, height]);
};
