import { STATUS } from './../../resources/enums';

export interface IuserState {
  isLoading: boolean;
  errorMessage: string | null;
  users: Iuser[];
  page: number;
  reachedLastPage: boolean;
  user: Iuser;
}

export enum UserEnum {
  ADMIN = 'ADMIN',
  RESTAURANT = 'RESTAURANT',
  USER = 'USER',
}

export interface IuserResponse {
  message: string;
  data: Iuser[];
}

export interface Iuser {
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
  successfulOrderCount?: number;
  status?: STATUS;
  type?: UserEnum;
  password?: string;
  disabled?: boolean;
}

export interface IuserFormObject {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordAgain: string;
}

export interface IuserErrorObject {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordAgain: string;
}

export interface IsingleUserResponse {
  message: string;
  data: Iuser;
}

export interface IforgottenPasswordEmail {
  email: string;
}
