import { ThunkAction } from 'redux-thunk';
import client from '../../api/client';
import { setAuthorizationToken } from '../../api/axiosInstance';
import { AppThunk } from '../store';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST,
  ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS,
  ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE,
} from './types';
import { IuserAuth } from './interfaces';
import { AppAction } from './../apptypes';

export const loginUser = (userCreds: IuserAuth): ThunkAction<void, {}, {}, AppAction> => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: LOGIN_REQUEST,
  });

  try {
    const res = await client.login(userCreds);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      error: error,
    });
  }
};

export const logOutUser = (): ThunkAction<void, {}, {}, AppAction> => async (
  dispatch,
): Promise<void> => {
  setAuthorizationToken('');
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const refreshAccessToken = (
  refreshToken: string,
  onTokenRefreshed: () => void,
): ThunkAction<void, {}, {}, AppAction> => async (dispatch): Promise<void> => {
  dispatch({
    type: REFRESH_TOKEN_REQUEST,
  });

  try {
    const res = await client.refreshAccessToken(refreshToken);
    setAuthorizationToken(res.accessToken);
    dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: res,
    });
    onTokenRefreshed();
  } catch (error) {
    dispatch({
      type: REFRESH_TOKEN_FAILURE,
      error: error,
    });
  }
};

export const addForgottenPasswordEmail = (email: string): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: ADD_FORGOTTEN_PASSWORD_EMAIL_REQUEST,
    payload: email,
  });
  try {
    await client.addForgottenPasswordEmail(email);
    dispatch({
      type: ADD_FORGOTTEN_PASSWORD_EMAIL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_FORGOTTEN_PASSWORD_EMAIL_FAILURE,
      error: error,
    });
  }
};
