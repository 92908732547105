import { AxiosError } from 'axios';
import { Icomplaint, IcomplaintType } from './interfaces';

export const GET_COMPLAINT_LIST_REQUEST = 'GET_COMPLAINT_LIST_REQUEST';
export const GET_COMPLAINT_LIST_SUCCESS = 'GET_COMPLAINT_LIST_SUCCESS';
export const GET_COMPLAINT_LIST_FAILURE = 'GET_COMPLAINT_LIST_FAILURE';
export const GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS = 'GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS';
export const COMPLAINT_LIST_LAST_PAGE_REACHED = 'COMPLAINT_LIST_LAST_PAGE_REACHED';
export const RESET_COMPLAINT_LIST = 'RESET_COMPLAINT_LIST';

export const GET_COMPLAINT_BY_ID_REQUEST = 'GET_COMPLAINT_BY_ID_REQUEST';
export const GET_COMPLAINT_BY_ID_SUCCESS = 'GET_COMPLAINT_BY_ID_SUCCESS';
export const GET_COMPLAINT_BY_ID_FAILURE = 'GET_COMPLAINT_BY_ID_FAILURE';

export const CLOSE_COMPLAINT_REQUEST = 'CLOSE_COMPLAINT_REQUEST';
export const CLOSE_COMPLAINT_SUCCESS = 'CLOSE_COMPLAINT_SUCCESS';
export const CLOSE_COMPLAINT_FAILURE = 'CLOSE_COMPLAINT_FAILURE';

export const GET_COMPLAINT_TYPES_REQUEST = 'GET_COMPLAINT_TYPES_REQUEST';
export const GET_COMPLAINT_TYPES_SUCCESS = 'GET_COMPLAINT_TYPES_SUCCESS';
export const GET_COMPLAINT_TYPES_FAILURE = 'GET_COMPLAINT_TYPES_FAILURE';

export interface IgetComplaintListRequestAction {
  type: typeof GET_COMPLAINT_LIST_REQUEST;
}

export interface IgetComplaintListSuccessAction {
  type: typeof GET_COMPLAINT_LIST_SUCCESS;
  payload: Icomplaint[];
}

export interface IgetComplaintListFailureAction {
  type: typeof GET_COMPLAINT_LIST_FAILURE;
  error: AxiosError;
}

export interface IgetComplaintListNextPageSuccessAction {
  type: typeof GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS;
  payload: {
    complaintList: Icomplaint[];
    page: number;
  };
}

export interface IcomplaintListLastPageReachedAction {
  type: typeof COMPLAINT_LIST_LAST_PAGE_REACHED;
}

export interface IresetComplaintListAction {
  type: typeof RESET_COMPLAINT_LIST;
}

export interface IgetComplaintByIdRequestAction {
  type: typeof GET_COMPLAINT_BY_ID_REQUEST;
}

export interface IgetComplaintByIdSuccessAction {
  type: typeof GET_COMPLAINT_BY_ID_SUCCESS;
  payload: Icomplaint;
}

export interface IgetComplaintByIdFailureAction {
  type: typeof GET_COMPLAINT_BY_ID_FAILURE;
  error: AxiosError;
}

export interface IcloseComplaintRequestAction {
  type: typeof CLOSE_COMPLAINT_REQUEST;
}

export interface IcloseComplaintSuccessAction {
  type: typeof CLOSE_COMPLAINT_SUCCESS;
}

export interface IcloseComplaintFailureAction {
  type: typeof CLOSE_COMPLAINT_FAILURE;
  error: AxiosError;
}

export interface IgetComplaintTypesRequestAction {
  type: typeof GET_COMPLAINT_TYPES_REQUEST;
}

export interface IgetComplaintTypesSuccessAction {
  type: typeof GET_COMPLAINT_TYPES_SUCCESS;
  payload: IcomplaintType[];
}

export interface IgetComplaintTypesFailureAction {
  type: typeof GET_COMPLAINT_TYPES_FAILURE;
  error: AxiosError;
}

export type ComplaintsActionTypes =
  | IgetComplaintListRequestAction
  | IgetComplaintListSuccessAction
  | IgetComplaintListFailureAction
  | IgetComplaintListNextPageSuccessAction
  | IcomplaintListLastPageReachedAction
  | IresetComplaintListAction
  | IgetComplaintByIdRequestAction
  | IgetComplaintByIdSuccessAction
  | IgetComplaintByIdFailureAction
  | IcloseComplaintRequestAction
  | IcloseComplaintSuccessAction
  | IcloseComplaintFailureAction
  | IgetComplaintTypesRequestAction
  | IgetComplaintTypesSuccessAction
  | IgetComplaintTypesFailureAction;
