import React, { useState, FormEvent, ReactElement, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Loader,
  BusinessHours,
  Button,
  TextInput,
  InputWithDropdown,
  Slider,
} from '../../components';
import {
  IrestaurantFormObject,
  IrestaurantErrorObject,
  IbusinessHourObject,
} from '../../redux/restaurants/interfaces';
import {
  statusDropDownItems,
  restaurantInitForm,
  restaurantInitError,
} from '../../resources/constants';
import { AppState } from '../../redux/store';
import * as actions from '../../redux/restaurants/restaurants.actions';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import {
  validateRestaurantForm,
  validateBusinessHours,
} from '../../utilities/functions/validateFunctions';
import useRestaurantTypes from '../../hooks/useRestaurantTypes';
import useCountryList from '../../hooks/useCountryList';

const AddRestaurantScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);
  const isLoading = useSelector((state: AppState) => state.restaurants.isLoading);
  const [restaurantTypes] = useRestaurantTypes();
  const [countryList] = useCountryList();

  const [form, setForm] = useState<IrestaurantFormObject>(restaurantInitForm);

  const [errors, setErrors] = useState<IrestaurantErrorObject>(restaurantInitError);

  const [businessHoursErrors, setBusinessHoursErrors] = useState(false);
  const firstUpdate = useRef(true);
  const [success, setSuccess] = useState(false);
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!isLoading && !errorMessage && success) {
      setSuccess(false);
      history.push('/restaurants_list');
    }
  }, [isLoading, errorMessage, history, success]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setBusinessHoursErrors(validateBusinessHours(form.businessHours));
    if (
      validateRestaurantForm(form, setErrors, t, true) &&
      !validateBusinessHours(form.businessHours)
    ) {
      // Cleaner code with cutting out the two props we don't use!
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { passwordAgain, businessHours, ...rest } = form;
      dispatch(
        actions.addRestaurant({
          ...rest,
          businessHours: businessHours.map((b) => ({ from: b.from, to: b.to, day: b.day })),
        }),
      );
      setSuccess(true);
    } else {
      setReRender(!reRender);
    }
  };

  const onReset = (): void => {
    setForm(restaurantInitForm);
    setErrors(restaurantInitError);
    history.push('/restaurants_list');
  };

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateStatusField = (selectedOption: string[]): void => {
    setForm({ ...form, status: selectedOption[0] });
  };
  const updateCountryField = (selectedOption: string[]): void => {
    setForm({
      ...form,
      address: { ...form.address, country: selectedOption[0] },
    });
  };
  const updateRestaurantTypeField = (selectedOptions: string[]): void => {
    setForm({
      ...form,
      restaurantType: selectedOptions,
    });
  };

  const updateAddressField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({
      ...form,
      address: {
        ...form.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateBusinessHours = (e: IbusinessHourObject[]): void => {
    setForm((prevState) => ({
      ...prevState,
      businessHours: e,
    }));
  };

  const updateSlider = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, dividendPercent: parseInt(e.target.value) / 100 });
  };

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderForm = (): ReactElement => {
    return (
      <>
        <form noValidate onSubmit={onSubmit} autoComplete="off" onReset={onReset}>
          <div className="addScreenHeader">{t('addRestaurantScreen.addRestaurant')}</div>
          <div className="columns">
            <div className="column is-half addFormColumn">
              <TextInput
                onChange={updateField}
                value={form.name}
                className={`is-medium ${errors.name && 'red-border'}`}
                placeholder={t('addRestaurantScreen.restaurantName')}
                type="text"
                name="name"
                error={errors.name}
              />
              <InputWithDropdown
                dropdownContent={restaurantTypes}
                value={form.restaurantType}
                onChange={(selectedOptions: string[]): void =>
                  updateRestaurantTypeField(selectedOptions)
                }
                className={`is-medium is-fullwidth ${errors.restaurantType ? 'red-border' : ''}`}
                placeholder={t('addRestaurantScreen.restaurantType')}
                multiple
                error={errors.restaurantType}
              />
              <Slider value={form.dividendPercent * 100} onChange={updateSlider} />
              <InputWithDropdown
                dropdownContent={countryList}
                value={form.address.country.length ? [form.address.country] : []}
                onChange={(selectedOption: string[]): void => updateCountryField(selectedOption)}
                className={`is-medium is-fullwidth ${errors.country ? 'red-border' : ''}`}
                placeholder={t('addRestaurantScreen.country')}
                numberOfSeparatedItems={1}
                error={errors.country}
              />
              <div className="columns formColumn">
                <div className="column is-one-third">
                  <TextInput
                    onChange={updateAddressField}
                    value={form.address.postalCode}
                    className={`is-medium ${errors.postalCode && 'red-border'} custom-number-input`}
                    placeholder={t('addRestaurantScreen.postalCode')}
                    type="number"
                    name="postalCode"
                    error={errors.postalCode}
                  />
                </div>
                <div className="column is-two-thirds">
                  <TextInput
                    onChange={updateAddressField}
                    value={form.address.city}
                    className={`is-medium ${errors.city && 'red-border'}`}
                    placeholder={t('addRestaurantScreen.city')}
                    type="text"
                    name="city"
                    error={errors.city}
                  />
                </div>
              </div>
              <div className="columns formColumn">
                <div className="column is-two-thirds formColumn">
                  <TextInput
                    onChange={updateAddressField}
                    value={form.address.streetName}
                    className={`is-medium ${errors.streetName && 'red-border'}`}
                    placeholder={t('addRestaurantScreen.streetName')}
                    type="text"
                    name="streetName"
                    error={errors.streetName}
                  />
                </div>
                <div className="column is-one-third">
                  <TextInput
                    onChange={updateAddressField}
                    value={form.address.streetNumber}
                    className={`is-medium ${errors.streetNumber && 'red-border'}`}
                    placeholder={t('addRestaurantScreen.streetNumber')}
                    type="text"
                    name="streetNumber"
                    error={errors.streetNumber}
                  />
                </div>
              </div>
              <h1 className="form-subtitle">{t('addRestaurantScreen.companyInfo')}</h1>
              {/**
               * COMAPANY INFO
               */}
              <TextInput
                onChange={updateField}
                value={form.companyName}
                className={`is-medium ${errors.companyName && 'red-border'}`}
                placeholder={t('addRestaurantScreen.companyName')}
                type="text"
                name="companyName"
                error={errors.companyName}
              />
              <TextInput
                onChange={updateField}
                value={form.companyAddress}
                className={`is-medium ${errors.companyAddress && 'red-border'}`}
                placeholder={t('addRestaurantScreen.companyAddress')}
                type="text"
                name="companyAddress"
                error={errors.companyAddress}
              />
              <TextInput
                onChange={updateField}
                value={form.mailingAddress}
                className={`is-medium ${errors.mailingAddress && 'red-border'}`}
                placeholder={t('addRestaurantScreen.mailingAddress')}
                type="text"
                name="mailingAddress"
                error={errors.mailingAddress}
              />
              <TextInput
                onChange={updateField}
                value={form.taxNumber}
                className={`is-medium ${errors.taxNumber && 'red-border'}`}
                placeholder={t('addRestaurantScreen.taxNumber')}
                type="text"
                name="taxNumber"
                error={errors.taxNumber}
              />
              <TextInput
                onChange={updateField}
                value={form.companyRegisterNumber}
                className={`is-medium ${errors.companyRegisterNumber && 'red-border'}`}
                placeholder={t('addRestaurantScreen.companyRegisterNumber')}
                type="text"
                name="companyRegisterNumber"
                error={errors.companyRegisterNumber}
              />
              <TextInput
                onChange={updateField}
                value={form.bankAccountNumber}
                className={`is-medium ${errors.bankAccountNumber && 'red-border'}`}
                placeholder={t('addRestaurantScreen.bankAccountNumber')}
                type="text"
                name="bankAccountNumber"
                error={errors.bankAccountNumber}
              />
              <TextInput
                onChange={updateField}
                value={form.firstName}
                className={`is-medium ${errors.firstName && 'red-border'}`}
                placeholder={t('addRestaurantScreen.contactFirstName')}
                type="text"
                name="firstName"
                error={errors.firstName}
              />
              <TextInput
                onChange={updateField}
                value={form.lastName}
                className={`is-medium ${errors.lastName && 'red-border'}`}
                placeholder={t('addRestaurantScreen.contactLastName')}
                type="text"
                name="lastName"
                error={errors.lastName}
              />
              <TextInput
                onChange={updateField}
                value={form.contactPhone}
                className={`is-medium ${errors.contactPhone && 'red-border'}`}
                placeholder={t('addRestaurantScreen.contactPhone')}
                type="text"
                name="contactPhone"
                error={errors.contactPhone}
              />
              <TextInput
                onChange={updateField}
                value={form.contactEmail}
                className={`is-medium ${errors.contactEmail && 'red-border'}`}
                placeholder={t('addRestaurantScreen.contactEmail')}
                type="text"
                autoComplete="off"
                name="contactEmail"
                error={errors.contactEmail}
              />
              <h1 className="form-subtitle">{t('addRestaurantScreen.loginInfo')}</h1>
              <TextInput
                onChange={updateField}
                value={form.email}
                className={`is-medium ${errors.email && 'red-border'}`}
                placeholder={t('email')}
                autoComplete="off"
                type="text"
                name="email"
                error={errors.email}
              />
              <TextInput
                onChange={updateField}
                value={form.password}
                className={`is-medium ${errors.password && 'red-border'}`}
                placeholder={t('password')}
                type="text"
                name="password"
                error={errors.password}
                autoComplete="new-password"
              />
              <TextInput
                onChange={updateField}
                value={form.passwordAgain}
                className={`is-medium ${errors.passwordAgain ? 'red-border' : ''}`}
                placeholder={t('passwordAgain')}
                type="text"
                name="passwordAgain"
                error={errors.passwordAgain}
                autoComplete="off"
              />
              <InputWithDropdown
                dropdownContent={statusDropDownItems}
                value={form.status.length ? [form.status] : []}
                onChange={(selectedOption: string[]): void => updateStatusField(selectedOption)}
                className={`is-medium is-fullwidth ${errors.status ? 'red-border' : ''}`}
                placeholder={t('status')}
                error={errors.status}
              />
            </div>
            <div className="column is-half">
              <BusinessHours
                onChange={updateBusinessHours}
                values={form.businessHours}
                hasError={businessHoursErrors}
              />
              {errors.businessHours && <p className="validationError">{errors.businessHours}</p>}
            </div>
          </div>
          <div className="addScreenButtonsContainer">
            <Button className="disabledColor addScreenButtons is-medium" type="reset">
              {t('resetChanges')}
            </Button>
            <Button className="addScreenButtons is-medium" type="submit">
              {t('saveChanges')}
            </Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="container">
      <div className="box addScreenContainer">
        {renderForm()}
        {renderLoading()}
      </div>
    </div>
  );
};

export default withErrorMessage(AddRestaurantScreen);
