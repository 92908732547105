import { STATUS } from '../../resources/enums';
import {
  ComplaintsActionTypes,
  GET_COMPLAINT_LIST_REQUEST,
  GET_COMPLAINT_LIST_SUCCESS,
  GET_COMPLAINT_LIST_FAILURE,
  GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS,
  COMPLAINT_LIST_LAST_PAGE_REACHED,
  RESET_COMPLAINT_LIST,
  GET_COMPLAINT_BY_ID_REQUEST,
  GET_COMPLAINT_BY_ID_FAILURE,
  GET_COMPLAINT_BY_ID_SUCCESS,
  CLOSE_COMPLAINT_FAILURE,
  CLOSE_COMPLAINT_REQUEST,
  CLOSE_COMPLAINT_SUCCESS,
  GET_COMPLAINT_TYPES_REQUEST,
  GET_COMPLAINT_TYPES_FAILURE,
  GET_COMPLAINT_TYPES_SUCCESS,
} from './types';
import { IcomplaintsState } from './interfaces';

const complaintsDefaultState: IcomplaintsState = {
  complaintList: [],
  selectedComplaint: null,
  isLoading: false,
  errorMessage: null,
  page: 0,
  reachedLastPage: false,
  complaintTypes: [],
};

export default (
  state = complaintsDefaultState,
  action: ComplaintsActionTypes,
): IcomplaintsState => {
  switch (action.type) {
    case GET_COMPLAINT_LIST_REQUEST:
    case GET_COMPLAINT_BY_ID_REQUEST:
    case CLOSE_COMPLAINT_REQUEST:
    case GET_COMPLAINT_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_COMPLAINT_LIST_FAILURE:
    case GET_COMPLAINT_BY_ID_FAILURE:
    case CLOSE_COMPLAINT_FAILURE:
    case GET_COMPLAINT_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.response?.data.message,
      };
    case GET_COMPLAINT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        complaintList: action.payload,
      };
    case GET_COMPLAINT_LIST_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        complaintList: [...state.complaintList, ...action.payload.complaintList],
        page: action.payload.page,
      };
    case COMPLAINT_LIST_LAST_PAGE_REACHED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        reachedLastPage: true,
      };
    case RESET_COMPLAINT_LIST:
      return complaintsDefaultState;
    case GET_COMPLAINT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        selectedComplaint: action.payload,
      };
    case CLOSE_COMPLAINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        selectedComplaint: state.selectedComplaint && {
          ...state.selectedComplaint,
          status: STATUS.INACTIVE,
        },
      };
    case GET_COMPLAINT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        complaintTypes: action.payload,
      };
    default:
      return state;
  }
};
