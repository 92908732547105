import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../redux/store';
import { Icountry } from '../../redux/restaurants/interfaces';
import { getCountryList } from '../../redux/restaurants/restaurants.actions';

/**
 * Hook for load the country list.
 */
const useCountryList = (): [Icountry[]] => {
  const { countryList } = useSelector((state: AppState) => state.restaurants);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countryList.length) {
      dispatch(getCountryList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [countryList];
};

export default useCountryList;
