import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import exceljs from 'exceljs';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { TableOptions } from 'react-table';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { Loader } from '../../components';
import { AppState } from '../../redux/store';
import ActionButtons from '../NewRegistrationsReportScreen/subcomponents/ActionButtons';
import ReportInterval from '../NewRegistrationsReportScreen/subcomponents/ReportInterval';
import { initialSelectionRange, SelectionRange } from '../../components/DateIntervalPicker';
import useDateConversion from '../../hooks/useDateConversion';
import ComplexTable from '../../components/ComplexTable';
import { fetchFoodParametersreport } from '../../redux/reports/reports.actions';

const columns = [
  { Header: 'Étel típusa', accessor: 'foodType' },
  { Header: 'Eladott darabszám', accessor: 'sold' },
  { Header: 'Eladott érték', accessor: 'cashFlow' },
  { Header: 'Feltöltés db/vétel', accessor: 'boughtRatio' },
];

const FoodParametersReportScreen: React.FC = () => {
  const { isLoading, foodParameters } = useSelector((state: AppState) => state.reports);
  const [tableData, setTableData] = useState<TableOptions<object>>({ data: [], columns: [] });
  const [dateRange, setDateRange] = useState<SelectionRange[]>(initialSelectionRange);
  const [from, to] = useDateConversion(dateRange);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchFoodParametersreport());
  }, [dispatch, dateRange]);

  useEffect(() => {
    setTableData({
      data: Object.entries(foodParameters).map(([key, value]) => {
        return {
          ...value,
          foodType: key,
        };
      }),
      columns,
    });
  }, [foodParameters]);

  const prepareXls = async (): Promise<void> => {
    const fileName = `FoodTypeReport${moment.unix(from).format('YYYY-MM-DD')}to${moment
      .unix(to)
      .format('YYYY-MM-DD')}`;

    const workbook = new exceljs.Workbook();
    const sheet = workbook.addWorksheet('Regisztrációk');

    sheet.addTable({
      name: 'Adatok',
      ref: 'A1',
      style: { theme: 'TableStyleLight1' },
      columns: [
        { name: 'Étel típusa' },
        { name: 'Eladott darabszám' },
        { name: 'Eladott érték' },
        { name: 'Feltöltés db/vétel' },
      ],
      rows: Object.entries(foodParameters).map(([key, value]) => {
        return [key, value.sold, value.cashFlow, value.boughtRatio];
      }),
    });

    // Populate sheet with data

    workbook.creator = 'Tudatos Team';
    workbook.created = new Date();
    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), fileName + '.xlsx');
  };

  const renderReport = (): ReactElement | null =>
    tableData.data.length && tableData.columns ? (
      <ComplexTable data={tableData.data} columns={tableData.columns} />
    ) : null;

  const renderData = (): ReactElement => (
    <>
      <div className="header">{t('foodParametersReportScreen.title')}</div>
      <div className="row">
        <div className="column">
          <ReportInterval dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        <div className="column">{renderReport()}</div>
      </div>
      <ActionButtons onXlsClick={prepareXls} />
    </>
  );

  return (
    <div className="container">
      <div className="box foodParametersReportScreenContainer">
        <Loader isLoading={isLoading} />
        {renderData()}
      </div>
    </div>
  );
};

export default withErrorMessage(FoodParametersReportScreen);
