import { TableOptions } from 'react-table';
import { ChartCoordinate, ChartProps } from '../../resources/interfaces';

export interface ReportParameter {
  id: string;
  label: string;
  type: 'radio' | 'checkbox';
  defaultChecked?: boolean;
  name?: string;
  checked?: boolean;
  childrens?: ReportParameter[];
}

export enum ReportTypeEnum {
  NEW_REGISTRATIONS = 'newRegistrations',
  ACTIVITY = 'activity',
  COMPLAINTS = 'complaints',
}

export interface IreportParameters {
  newRegistrations: ReportParameter[];
  activity: ReportParameter[];
  complaints: ReportParameter[];
}

export interface IreportParametersResponse {
  data: IreportParameters;
  message: string;
}

export interface InewRegistrationsReportResponse {
  data: ChartCoordinate[];
  message: string;
}

export interface InewRegistrationReportPayload {
  data: ChartCoordinate[];
}
export interface ActivityAllItem {
  label: string;
  value: number;
}
export interface IactivityAllResponse {
  data: {
    cashFlow: number;
    offerCount: number;
    successfulPaymentCount: number;
    unsuccessfulPaymentCount: number;
    sumPaymentCount: number;
  };
  message: string;
}

interface IactivityReport {
  all: ActivityAllItem[];
  user: TableOptions<object>[];
  restaurant: TableOptions<object>[];
  isLastPage: boolean;
  restaurantCount: number;
  numberOfRegisteredUsers: number;
}

interface IactivityRestaurantReport {
  restaurantCount: number;
  isLastPage: boolean;
  restaurants: {
    name: string;
    id: string;
    offersCount: number;
    ordersCount: number;
    successfulOrdersCount: number;
    rejectedOrdersCount: number;
    makings: number;
  }[];
}

export interface IactivityRestaurantPayload {
  restaurantCount: number;
  isLastPage: boolean;
  restaurant: TableOptions<object>[];
}
export interface IactivityUsersPayload {
  numberOfRegisteredUsers: number;
  isLastPage: boolean;
  user: TableOptions<object>[];
}

export interface IactivityUsersResponse {
  data: {
    isLastPage: boolean;
    numberOfRegisteredUsers: number;
    users: {
      userName: string;
      successfulOrdersCount: number;
      unsuccessfulOrdersCount: number;
      allOrdersCount: number;
      cashFlow: string;
    }[];
  };
}

export interface IactivityRestaurantsReportResponse {
  message: string;
  data: IactivityRestaurantReport;
}

export interface IactivityReportResponse {
  data: {
    user: TableOptions<object>[];
    numberOfRegisteredProviders: number;
    numberOfRegisteredUsers: number;
  };
  message: string;
}

export interface IcomplaintsReportResponse {
  data: ChartProps[];
  message: string;
}

export interface IfoodParametersReportResponse {
  data: IfoodParamMap<IfoodParamRow>;
  message: string;
}

export interface IfoodParamRow {
  foodType?: string;
  sold: number;
  cashFlow: number;
  boughtRatio: number;
}

export interface IfoodParamMap<T> {
  [foodType: string]: T;
}

export interface IreportsState {
  isLoading: boolean;
  loadingXls: boolean;
  errorMessage: string | null;
  userNumber: number;
  totalCost: number;
  completedOffers: number;
  parameters: IreportParameters;
  newRegistrations: ChartCoordinate[];
  activity: IactivityReport;
  complaints: ChartProps[];
  foodParameters: IfoodParamMap<IfoodParamRow>;
}

export interface IrestaurantXlsResponse {
  data: RestaurantXlsResponse[];
  message: string;
}

export interface RestaurantXlsResponse {
  orders: Order[];
  name: string;
  dividendPercent: number;
  _id: string;
}

export interface IcomplaintXlsResponse {
  data: ComplaintXlsResponse[];
  message: string;
}

export interface ComplaintXlsResponse {
  complaints: ComplaintDataForRestaurant[];
  _id: string;
}

export interface ComplaintDataForRestaurant {
  restaurant: string;
  count: number;
}

export interface RegistrationXlsData {
  restaurants: ChartCoordinate[];
  users: ChartCoordinate[];
}

interface Order {
  userName: string;
  cart: Cart[];
  paymentDate: string;
  price: number;
}

interface Cart {
  name: string;
  quantity: number;
  price: { originalPrice: number; discountPrice: number; currency: string };
}
