import axios from 'axios';
import i18next from 'i18next';
import { BASE_URL } from './urls';

export const getCurrentLanguage = (): string => {
  return i18next.language;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': getCurrentLanguage(),
  },
});

export const axiosFormDataInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': getCurrentLanguage(),
  },
});

export const setAuthorizationToken = (token: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
  axiosFormDataInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
};

export const getAuthorizationToken = (): string =>
  axiosInstance.defaults.headers.common['Authorization'];

export default axiosInstance;
