import * as React from 'react';

interface LoaderProps {
  isLoading: boolean;
}

/**
 *
 * @param isLoading - if true the loader shows up.
 */
const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className="spinner-container">
      <div className="spinner" />
      <div className="spinner" />
      <div className="spinner" />
    </div>
  ) : null;
};

export default Loader;
