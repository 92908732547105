import React, { useMemo, ReactElement } from 'react';
import { useTable, TableOptions, useSortBy, usePagination } from 'react-table';
import SortingIcon from './subcomponents/SortingIcon';
// import TablePagination from './subcomponents/TablePagination';

/**
 * Table with sorting and pagination.
 * Displayed on the activity report screen.
 * @param data - The table's data.
 * @param columns - The table's columns.
 */
const ComplexTable: React.FC<TableOptions<object>> = ({
  data,
  columns,
  previousPage,
  nextPage,
  pageNumber,
  totalPages,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageSize: 15,
      },
    },
    useSortBy,
    usePagination,
  );
  const navigationButtons = [
    { label: '<', onClick: previousPage, disabled: pageNumber === 0 },
    { label: '>', onClick: nextPage, disabled: pageNumber + 1 === totalPages },
  ];
  const renderNavigationButton = (): ReactElement[] =>
    navigationButtons.map(({ label, disabled, onClick }, index) => (
      <button
        key={index}
        className="tablePaginationButton button is-small"
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </button>
    ));

  return (
    <>
      <table {...getTableProps()} className="table is-fullwidth is-striped">
        <thead>
          {headerGroups.map((headerGroup, headerIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerIndex} className="is-bold">
              {headerGroup.headers.map((column, columnIndex) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                  <div className="complexTableHeaderColumn">
                    <div>{column.render('Header')}</div>
                    <div className="sortingIconContainer">
                      <SortingIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page, rowIndex) => {
            prepareRow(page);
            return (
              <tr {...page.getRowProps()} key={rowIndex}>
                {page.cells.map((cell, cellIndex) => {
                  return (
                    <td {...cell.getCellProps()} key={cellIndex}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {totalPages && pageNumber ? (
        <div className="tablePagination">
          <span className="button is-secondary is-small is-static paginationLabel">{`${
            pageNumber + 1
          } / ${totalPages}`}</span>
          {renderNavigationButton()}
        </div>
      ) : null}
    </>
  );
};

export default ComplexTable;
