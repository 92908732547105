import { Iuser } from '../users/interfaces';
import {
  ColleaguesActionTypes,
  GET_COLLEAGUES_REQUEST,
  GET_COLLEAGUES_SUCCESS,
  GET_COLLEAGUES_FAILURE,
  GET_COLLEAGUES_NEXT_PAGE_SUCCESS,
  GET_COLLEAGUES_LAST_PAGE_REACHED,
  RESET_COLLEAGUES_LIST,
  ADD_COLLEAGUE_REQUEST,
  ADD_COLLEAGUE_SUCCESS,
  ADD_COLLEAGUE_FAILURE,
  GET_COLLEAGUE_REQUEST,
  GET_COLLEAGUE_SUCCESS,
  GET_COLLEAGUE_FAILURE,
} from './types';
import { IcolleagueState } from './interfaces';

const defaultSelectedUserState: Iuser = {
  firstName: '',
  email: '',
  lastName: '',
};

const colleagueDefaultState: IcolleagueState = {
  isLoading: false,
  colleagues: [],
  selectedColleague: defaultSelectedUserState,
  errorMessage: null,
  page: 0,
  reachedLastPage: false,
};

export default (state = colleagueDefaultState, action: ColleaguesActionTypes): IcolleagueState => {
  switch (action.type) {
    case ADD_COLLEAGUE_REQUEST:
    case GET_COLLEAGUES_REQUEST:
    case GET_COLLEAGUE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case ADD_COLLEAGUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case GET_COLLEAGUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        colleagues: action.payload,
      };
    case GET_COLLEAGUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        selectedColleague: action.payload,
      };
    case ADD_COLLEAGUE_FAILURE:
    case GET_COLLEAGUES_FAILURE:
    case GET_COLLEAGUE_FAILURE:
      return {
        ...state,
        isLoading: false,
        colleagues: [],
        selectedColleague: defaultSelectedUserState,
        errorMessage: action.error.response?.data.message,
      };
    case GET_COLLEAGUES_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        colleagues: [...state.colleagues, ...action.payload],
        page: action.page,
      };
    case GET_COLLEAGUES_LAST_PAGE_REACHED:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        reachedLastPage: true,
      };
    case RESET_COLLEAGUES_LIST:
      return {
        ...colleagueDefaultState,
      };

    default:
      return state;
  }
};
