import React from 'react';

interface SortingIconProps {
  isSorted: boolean;
  isSortedDesc?: boolean;
}

/**
 * Sorting icon component for the header columns of complex table.
 * @param isSorted - Set to true when the column is sorted.
 * @param isSortedDesc - Set to true when the sorting is descending.
 */
const SortingIcon: React.FC<SortingIconProps> = ({ isSorted, isSortedDesc }) => {
  return (
    <span className="material-icons sortingIcon">
      {isSorted ? (isSortedDesc ? 'arrow_upward' : 'arrow_downward') : ''}
    </span>
  );
};

export default SortingIcon;
