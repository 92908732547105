import React, { useState, FormEvent, ReactElement, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import { IuserFormObject, IuserErrorObject } from '../../redux/users/interfaces';
import { validateEditColleague } from '../../utilities/functions/validateFunctions';
import * as actions from '../../redux/colleagues/colleagues.actions';
import { Loader, TextInput, Button, Modal } from '../../components';
import { useQuery } from '../../hooks/useQuery';
import { initialFormState } from '../../resources/constants';

const EditCollagueScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);
  const isLoading = useSelector((state: AppState) => state.colleagues.isLoading);
  const selectedColleague = useSelector((state: AppState) => state.colleagues.selectedColleague);
  const initialErrorState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
  };
  const [form, setForm] = useState<IuserFormObject>(initialFormState);
  const [errors, setErrors] = useState<IuserErrorObject>(initialErrorState);
  const firstUpdate = useRef(true);
  const [success, setSuccess] = useState(false);
  const [isActive, setModalActive] = useState(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!isLoading && !errorMessage && success) {
      setSuccess(false);
      history.push('/colleagues_list');
    }
  }, [isLoading, errorMessage, history, success]);

  // GET Admin user based on ID
  useEffect(() => {
    const id = query.get('id');
    dispatch(actions.getColleague(id || ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load form with values
  useEffect(() => {
    const { firstName, lastName, email } = selectedColleague;
    setForm({
      ...form,
      firstName,
      lastName,
      email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColleague]);

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (validateEditColleague(form, setErrors, t)) {
      const colleague = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
      };
      const id = query.get('id');
      dispatch(actions.editColleague(id || '', colleague));
      setSuccess(true);
    }
  };

  const onReset = (): void => {
    setForm(initialFormState);
    setErrors(initialErrorState);
    history.push('/colleagues_list');
  };

  const onDelete = (): void => {
    dispatch(actions.deleteColleague(selectedColleague._id));
    history.push('/colleagues_list');
  };

  const renderLoading = (): ReactElement => {
    return <Loader isLoading={isLoading} />;
  };

  const renderForm = (): ReactElement => {
    return (
      <>
        <form noValidate onSubmit={onSubmit} onReset={onReset} className="addformContainer">
          <div className="addScreenHeader">{t('modifyColleagueScreen.modifyColleague')}</div>
          {renderLoading()}
          <div className="columns">
            <div className="column is-half addFormColumn">
              <TextInput
                onChange={updateField}
                value={form.firstName}
                className={`is-medium ${errors.firstName && 'red-border'}`}
                placeholder={t('firstName')}
                type="text"
                required
                name="firstName"
                error={errors.firstName}
              />
              <TextInput
                onChange={updateField}
                value={form.lastName}
                className={`is-medium ${errors.lastName && 'red-border'}`}
                placeholder={t('lastName')}
                type="text"
                required
                name="lastName"
                error={errors.lastName}
              />
            </div>
            <div className="column is-half addFormColumn">
              <TextInput
                onChange={updateField}
                value={form.email}
                className={`is-medium ${errors.email && 'red-border'}`}
                placeholder={t('email')}
                type="email"
                required
                name="email"
                error={errors.email}
              />
              <TextInput
                onChange={updateField}
                value={form.password}
                className={`is-medium ${errors.password && 'red-border'}`}
                placeholder={t('password')}
                type="password"
                required
                name="password"
                error={errors.password}
                autoComplete="off"
              />
              <TextInput
                onChange={updateField}
                value={form.passwordAgain}
                className={`is-medium ${errors.passwordAgain && 'red-border'}`}
                placeholder={t('passwordAgain')}
                type="password"
                required
                name="passwordAgain"
                error={errors.passwordAgain}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="addScreenButtonsContainer">
            <Button
              className="disabledColor addScreenButtons is-medium"
              type="button"
              onClick={(): void => setModalActive(true)}
            >
              {t('modifyColleagueScreen.deleteColleague')}
            </Button>
            <Button className="disabledColor addScreenButtons is-medium" type="reset">
              {t('resetChanges')}
            </Button>
            <Button className="addScreenButtons is-medium" type="submit">
              {t('saveChanges')}
            </Button>
            <Modal
              isActive={isActive}
              closeModal={(): void => setModalActive(false)}
              title={t('modifyColleagueScreen.deleteColleagueConfirm')}
              onConfirm={onDelete}
            />
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="container">
      <div className="box addScreenContainer">
        {renderForm()}
        {renderLoading()}
      </div>
    </div>
  );
};

export default withErrorMessage(EditCollagueScreen);
