import React, { ReactElement, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../redux/store';
import { resetError } from '../../../redux/error/error.action';

const withErrorMessage = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => ({
  ...props
}): ReactElement<unknown, any> => {
  const { t } = useTranslation();
  const errorMessage = useSelector((state: AppState) => state.error.errorMessage);
  const dispatch = useDispatch();
  const errorModal = createRef<HTMLElement>();

  const handleClose = (): void => {
    errorModal.current?.classList.add('animate__backOutUp');
    dispatch(resetError());
  };
  return errorMessage ? (
    <>
      <div className="container">
        <article
          ref={errorModal}
          className="errorHoc animate__animated animate__backInDown message is-danger"
        >
          <div className="message-header">
            <p>{t('error')}</p>
            <button className="delete" onClick={(): void => handleClose()} aria-label="delete" />
          </div>
          <div className="message-body">{errorMessage}</div>
        </article>
        <Component {...(props as P)} />
      </div>
    </>
  ) : (
    <Component {...(props as P)} />
  );
};
//https://github.com/Microsoft/TypeScript/issues/28938 props as P

export default withErrorMessage;
