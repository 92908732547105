import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import usersReducers from '../users/users.reducer';
import authenticationReducer from '../authentication/authentication.reducer';
import restaurantsReducer from '../restaurants/restaurants.reducer';
import colleaguesReducer from '../colleagues/colleagues.reducer';
import complaintsReducer from '../complaints/complaints.reducer';
import errorReducer from '../error/error.reducer';
import reportsReducer from '../reports/reports.reducer';
import notificationsReducer from '../notifications/notifications.reducer';
import { AppAction } from '../apptypes';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'colleagues',
    'complaints',
    'error',
    'notifications',
    'reports',
    'restaurants',
    'users',
  ],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['refreshToken'],
  blacklist: ['accessToken', 'user', 'isLoading', 'errorMessage', 'isAuthenticated', 'isSuccess'],
};

export const rootReducer = combineReducers({
  users: usersReducers,
  auth: persistReducer(authPersistConfig, authenticationReducer),
  restaurants: restaurantsReducer,
  colleagues: colleaguesReducer,
  complaints: complaintsReducer,
  error: errorReducer,
  reports: reportsReducer,
  notifications: notificationsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AppAction>;

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk))
    : applyMiddleware(thunk),
);

export const persistor = persistStore(store);
