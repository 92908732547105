import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IbusinessHourObject } from '../../redux/restaurants/interfaces';
import { CheckboxWithLabel, InputWithLabel } from '..';
import { defaultBusinessHours } from '../../resources/constants';

interface BusinessHoursProps {
  onChange: (state: IbusinessHourObject[]) => void;
  values: IbusinessHourObject[];
  hasError?: boolean;
}

const BusinessHours: React.FC<BusinessHoursProps> = ({ values, onChange, hasError }) => {
  const { t } = useTranslation();
  const [businessHours, setBusinessHours] = useState<IbusinessHourObject[]>(defaultBusinessHours);
  const [triggerChange, setTriggerChange] = useState<boolean>(false);

  // COMPONENT DID MOUNT
  useEffect(() => {
    setBusinessHours(values);
  }, [values]);

  useEffect(() => {
    onChange(businessHours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerChange]);

  const checkboxChange = (e: React.ChangeEvent<HTMLInputElement>, day: string): void => {
    setBusinessHours(
      businessHours.map((businessHour) =>
        businessHour.day === day
          ? {
              ...businessHour,
              checked: e.currentTarget.checked,
              from: e.currentTarget.checked ? businessHour.from : '',
              to: e.currentTarget.checked ? businessHour.to : '',
            }
          : businessHour,
      ),
    );
    setTriggerChange(!triggerChange);
  };

  const toChange = (value: string, day: string): void => {
    setBusinessHours(
      businessHours.map((businessHour) =>
        businessHour.day === day ? { ...businessHour, to: value } : businessHour,
      ),
    );
    setTriggerChange(!triggerChange);
  };

  const fromChange = (value: string, day: string): void => {
    setBusinessHours(
      businessHours.map((businessHour) =>
        businessHour.day === day ? { ...businessHour, from: value } : businessHour,
      ),
    );
    setTriggerChange(!triggerChange);
  };
  return (
    <div className="businessHoursContainer">
      <div className="businessHoursHeader">{t('addRestaurantScreen.openingHours')}</div>
      {businessHours.map((businessHour) => {
        const { from, to, day, checked } = businessHour;
        const isDefaultChecked = checked || (!!from && !!to);
        return (
          <div className="businessDay" key={day}>
            <CheckboxWithLabel
              label={t(`days.${day}`)}
              defaultChecked={isDefaultChecked}
              onChange={(e): void => checkboxChange(e, day)}
            />
            <InputWithLabel
              label={t('addRestaurantScreen.from')}
              disabled={!isDefaultChecked}
              value={isDefaultChecked ? from : ''}
              callback={(value: string): void => fromChange(value, day)}
              error={hasError ? 'error' : ''}
            />
            <InputWithLabel
              label={t('addRestaurantScreen.to')}
              disabled={!isDefaultChecked}
              value={isDefaultChecked ? to : ''}
              callback={(value: string): void => toChange(value, day)}
              error={hasError ? 'error' : ''}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BusinessHours;
